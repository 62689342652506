import styled from '@emotion/styled';

import { Button, TextArea } from '../../elements';

export const InputTextArea = styled(TextArea)`
  resize: vertical;
`;

export const ButtonStyled = styled(Button)`
  transition: all 300ms ease-in-out;
  cursor: pointer;
  &:hover {
    background: #000;
  }
`;
