import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';

import { FlexView } from '../../elements';
import { BRANDS } from '../partners/partners.data';
import ItemGrid from './item-grid';
const PartnersList: FC = () => {
  const partners = useLocation();
  return (
    <FlexView
      display="grid"
      gridTemplateColumns={['auto', 'auto auto', 'repeat(auto-fill, 14rem)']}
      width="100%"
      justifyContent="space-around"
      mt={['unset', 'unset', 'XL']}
    >
      {BRANDS.sort(({ imgSrc }, b) => (imgSrc < b.imgSrc ? -1 : 1)).map(
        (item) =>
          [item.services, ''].includes(
            partners.pathname.split('/brands/')[1]
          ) && <ItemGrid picture={item.imgSrc} link={item.link} key={v4()} />
      )}
    </FlexView>
  );
};

export default PartnersList;
