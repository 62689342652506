import React, { FC } from 'react';

import { FlexView } from '../../elements';
import Slider from '../slider';
import ItemSlide from './item-slide';

const SlideShow: FC = () => (
  <FlexView width="100vw" bg={['text', 'text', 'saturated']} height="100vh">
    <Slider type="hero">
      <ItemSlide
        title="Trabalhamos com as melhores marcas"
        subTitle=""
        picture={
          process.env.PUBLIC_URL +
          '/engineer-working-checking-maintenance-equipment-wiring-plc-cabinet-min.webp'
        }
      />
      <ItemSlide
        title="Temos os mais diferenciados produtos eléctricos"
        subTitle=""
        picture={process.env.PUBLIC_URL + '/multimeter-placed-tools-min.webp'}
      />
      <ItemSlide
        title="Garantimos a qualidade dos nossos produtos para iluminar o seu negócio"
        subTitle=""
        picture={
          process.env.PUBLIC_URL + '/light-bulb-lit-many-lights-min.webp'
        }
      />
    </Slider>
  </FlexView>
);

export default SlideShow;
