import React, { FC, SVGAttributes } from 'react';

const WhatsAppSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      d="M14.58 11.9856C14.3294 11.8588 13.1087 11.2631 12.8806 11.1781C12.6525 11.0969 12.4869 11.0544 12.3206 11.305C12.1581 11.5494 11.6794 12.1088 11.5331 12.2719C11.3869 12.435 11.2431 12.4475 10.9963 12.3369C10.7456 12.21 9.945 11.9494 8.99438 11.0969C8.25187 10.4362 7.7575 9.6225 7.61063 9.37187C7.46438 9.12438 7.59438 8.98438 7.71812 8.86063C7.83187 8.74687 7.96875 8.57438 8.09562 8.42438C8.21625 8.27437 8.255 8.17375 8.34313 8.01125C8.42437 7.83562 8.3825 7.69875 8.32063 7.575C8.25875 7.45125 7.76062 6.22438 7.5525 5.73563C7.35375 5.25063 7.14562 5.3125 6.9925 5.3125C6.84937 5.29938 6.68313 5.29938 6.5175 5.29938C6.35188 5.29938 6.08125 5.36125 5.85312 5.59875C5.625 5.84938 4.98063 6.44812 4.98063 7.65938C4.98063 8.87375 5.8725 10.0487 5.99625 10.2244C6.12313 10.3869 7.75063 12.8869 10.2475 13.9612C10.8431 14.2119 11.3056 14.3619 11.6669 14.4856C12.2625 14.6744 12.8062 14.6481 13.2356 14.5862C13.7106 14.5081 14.7069 13.9838 14.9156 13.3981C15.1275 12.8088 15.1275 12.3206 15.0656 12.21C15.0037 12.0962 14.8412 12.0344 14.5906 11.9237L14.58 11.9856ZM10.0519 18.125H10.0387C8.56063 18.125 7.09938 17.7244 5.82313 16.9756L5.52375 16.7969L2.39875 17.6106L3.23875 14.57L3.03688 14.2575C2.21154 12.9452 1.77369 11.4265 1.77375 9.87625C1.77375 5.33875 5.48813 1.6375 10.0581 1.6375C12.2719 1.6375 14.3488 2.5 15.9113 4.0625C16.682 4.82412 17.2935 5.73161 17.7098 6.73204C18.1262 7.73246 18.3391 8.80578 18.3363 9.88937C18.33 14.4237 14.6187 18.125 10.055 18.125H10.0519ZM17.0994 2.87437C15.1981 1.03813 12.6981 0 10.0387 0C4.55375 0 0.0875 4.44687 0.084375 9.91187C0.084375 11.6569 0.54 13.3594 1.4125 14.8662L0 20L5.28 18.6231C6.74269 19.4117 8.37764 19.8265 10.0394 19.8306H10.0425C15.5306 19.8306 19.9969 15.3837 20 9.915C20 7.26875 18.9681 4.77812 17.0869 2.90625L17.0994 2.87437Z"
      fill={props?.fill || 'white'}
    />
  </svg>
);

export default WhatsAppSVG;
