import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Routes, RoutesEnum } from '../../constants/routes';
import { FlexView, Text } from '../../elements';
import { ButtonHover, FlexViewScale } from './know-more.styles';
import { ItemsProps } from './know-more.types';
import Thunder from './thunder';
const Item: FC<ItemsProps> = ({ description, image }) => (
  <FlexViewScale
    background={`url('${image}') no-repeat center center`}
    backgroundSize="cover"
    width={['100vw', '100vw', '100%']}
    height={['26.625rem', '26.625rem', '25.625rem']}
  >
    <FlexView
      width="100%"
      height="100%"
      bg="rgba(3, 3, 3, 0.6);"
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      <Thunder />
      <FlexView zIndex={2} alignItems="center" flexDirection="column">
        <Text
          variant="title3"
          color="textInverted"
          mb="L"
          textAlign="center"
          fontSize={['1.2rem', '1.7rem', '1.7rem']}
        >
          {description}
        </Text>
        <Link
          aria-label="Clique aqui para ser redirecionado a pág. dos contactos"
          to={`${Routes[RoutesEnum.Partners]}${description}`}
        >
          <ButtonHover
            border="none"
            borderRadius="15rem"
            width={['2.25rem', '2.25rem', '3.5rem']}
            height={['2.25rem', '2.25rem', '3.5rem']}
            color="textInverted"
            bg="primary"
            name="plus"
            aria-label="plus-btn"
            fontWeight="700"
            fontSize={['1.3rem', '1.3rem', '1.8rem']}
            pb={['S', 'S', 'M']}
          >
            +
          </ButtonHover>
        </Link>
      </FlexView>
    </FlexView>
  </FlexViewScale>
);

export default Item;
