import React, { FC } from 'react';

import { FlexView } from '../../elements';
import { Container } from '../shared';
const NeedHelpWrapper: FC = ({ children }) => (
  <FlexView
    height="100vh"
    width="100vw"
    background={`url('${
      process.env.PUBLIC_URL + '/need-help.webp'
    }') no-repeat center center`}
    backgroundSize="cover"
    alignItems={['center', 'center', 'center']}
    backgroundPosition={['right', 'right', 'unset']}
  >
    <Container
      justifyContent={['center', 'center', 'unset']}
      pl={['unset', 'unset', '12rem']}
    >
      {children}
    </Container>
  </FlexView>
);

export default NeedHelpWrapper;
