import styled from '@emotion/styled';
import {
  background,
  border,
  color,
  compose,
  layout,
  space,
  typography,
} from 'styled-system';

const TextArea = styled.textarea(
  compose(color, space, border, layout, typography, background)
);

export default TextArea;
