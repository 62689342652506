import { useTheme } from '@emotion/react';
import React, { FC } from 'react';

import { Theme } from '../../design-system/light-theme';
import { FlexView, Text } from '../../elements';
import SocialMedias from '../social-midias';

const SocialMidiasInfo: FC = () => {
  const theme = useTheme() as Theme;
  return (
    <FlexView
      justifyContent="space-between"
      my={['unset', 'unset', '2rem']}
      pb="2rem"
      flexDirection={['column', 'column', 'row']}
    >
      <FlexView flexDirection="column">
        <Text
          variant="normal"
          color="textInverted"
          fontSize={['0.9rem', '0.9rem', '1rem']}
          fontWeight="400"
          textAlign={['center', 'center', 'unset']}
          mb="S"
        >
          Copyright © 2022{' '}
          <Text as="span" color="secondary">
            Angoelétrica
          </Text>
          . All Rights Reserved.
        </Text>
        <a
          aria-label="Link para NSDEV"
          href="https://www.nsdevteam.com/"
          target="_blank"
          rel="noreferrer"
        >
          <Text
            variant="normal"
            color="textInverted"
            fontSize={['0.9rem', '0.9rem', '1rem']}
            fontWeight="400"
            textAlign={['center', 'center', 'unset']}
          >
            Developed by{' '}
            <Text as="span" color="secondary">
              NSDEV
            </Text>
          </Text>
        </a>
      </FlexView>
      <FlexView
        width={['100vw', '100vw', '8rem']}
        height="1.25rem"
        justifyContent={['space-evenly', 'space-evenly', 'space-between']}
        mt={['L', 'L', 'unset']}
      >
        <SocialMedias defaultColor={theme.colors.textInverted} />
      </FlexView>
    </FlexView>
  );
};
export default SocialMidiasInfo;
