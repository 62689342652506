import React, { FC, SVGAttributes } from 'react';

const CartSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" {...props}>
    <path
      d="M17.2764 5.88288C17.2036 5.77764 17.1063 5.69163 16.993 5.6322C16.8797 5.57278 16.7536 5.54171 16.6257 5.54167H5.80594L4.89236 3.34875C4.77257 3.05997 4.56973 2.81326 4.30955 2.6399C4.04938 2.46655 3.74358 2.37435 3.43094 2.375H1.58398V3.95834H3.43094L7.18661 12.9715C7.24677 13.1157 7.34825 13.2388 7.47828 13.3255C7.60831 13.4121 7.76107 13.4583 7.91732 13.4583H14.2507C14.5808 13.4583 14.8761 13.2533 14.9924 12.9453L17.3674 6.612C17.4123 6.49216 17.4275 6.36322 17.4116 6.23624C17.3958 6.10925 17.3494 5.988 17.2764 5.88288ZM13.702 11.875H8.44536L6.46619 7.125H15.4833L13.702 11.875Z"
      fill={props?.fill}
    />
    <path
      d="M8.3125 16.625C8.96834 16.625 9.5 16.0933 9.5 15.4375C9.5 14.7817 8.96834 14.25 8.3125 14.25C7.65666 14.25 7.125 14.7817 7.125 15.4375C7.125 16.0933 7.65666 16.625 8.3125 16.625Z"
      fill={props?.fill}
    />
    <path
      d="M13.8535 16.625C14.5094 16.625 15.041 16.0933 15.041 15.4375C15.041 14.7817 14.5094 14.25 13.8535 14.25C13.1977 14.25 12.666 14.7817 12.666 15.4375C12.666 16.0933 13.1977 16.625 13.8535 16.625Z"
      fill={props?.fill}
    />
  </svg>
);

export default CartSVG;
