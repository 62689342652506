import { useTheme } from '@emotion/react';
import React, { FC } from 'react';

import { Theme } from '../../design-system/light-theme';
import { FlexView, Text } from '../../elements';
import { LocationSVG, PhoneSVG } from '../svg';
import { ItemsAddressProps } from './top-bar.types';

const ItemAddress: FC<ItemsAddressProps> = ({ address, phone }) => {
  const theme = useTheme() as Theme;

  return (
    <FlexView
      flexDirection="column"
      ml={['unset', 'unset', 'L']}
      mb={['L', 'L', 'unset']}
    >
      <Text
        variant="small"
        color="semiSaturated"
        fontSize={['1.1rem', '1.1rem', 'S']}
        cursor="pointer"
      >
        <LocationSVG
          width="0.75rem"
          height="0.75rem"
          fill={theme.colors.primary}
        />
        {' ' + address}
      </Text>
      <a
        href={`tel:${phone.replaceAll(' ', '')}`}
        target="_blank"
        rel="noreferrer"
      >
        <Text
          variant="small"
          color="semiSaturated"
          fontSize={['1.1rem', '1.1rem', 'S']}
          cursor="pointer"
          mt={['M', 'M', 'S']}
        >
          <PhoneSVG
            width="0.75rem"
            height="0.75rem"
            fill={theme.colors.primary}
          />
          {' ' + phone}
        </Text>
      </a>
    </FlexView>
  );
};
export default ItemAddress;
