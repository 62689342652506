import React, { FC } from 'react';

import { Maps, PageWrapper, SendMessage } from '../components';
import { RoutesEnum } from '../constants/routes';

const Contact: FC = () => (
  <PageWrapper
    pageTitle="Contacto"
    description="Contactos"
    link={RoutesEnum.Contacts}
  >
    <Maps />
    <SendMessage />
  </PageWrapper>
);

export default Contact;
