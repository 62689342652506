import React, { FC } from 'react';
import { v4 } from 'uuid';

import { FlexView } from '../../elements';
import { Container } from '../shared';
import FeatureStore from './feature-store';
import { FEATURES_STORES } from './footer.data';
import InfoPlace from './info-place';
import Links from './links';
import Map from './map';
import SocialMidias from './social-midias-info';

const Footer: FC = () => (
  <FlexView
    width="100vw"
    bg="saturated"
    px={['unset', 'unset', '6rem']}
    mt={['auto', 'auto', '0']}
    as="footer"
  >
    <Container
      position="relative"
      display="flex"
      flexDirection="column"
      pt={['3rem', '3rem', '6.5rem']}
    >
      <FlexView
        width="100%"
        justifyContent="space-between"
        flexDirection={['column', 'column', 'row']}
        flexWrap="wrap"
        px={['1.5rem', '1.5rem', 'unset']}
      >
        <Links />
        <InfoPlace />
        <FlexView display="flex" flexDirection="column">
          {FEATURES_STORES.map((item) => (
            <FeatureStore
              title={item.title}
              location={item.location}
              phone={item.phone}
              key={v4()}
            />
          ))}
        </FlexView>
        {false && <Map />}
      </FlexView>
      <SocialMidias />
    </Container>
  </FlexView>
);

export default Footer;
