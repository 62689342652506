import { FC } from 'react';

import { RoutesEnum } from '../constants/routes';
import About from '../views/about';
import Components from '../views/components';
import Contacts from '../views/contact';
import Home from '../views/home';
import OtherPage from '../views/other-page';
import Partners from '../views/partners';

export const RouteComponent: Record<RoutesEnum, FC> = {
  [RoutesEnum.Home]: Home,
  [RoutesEnum.Contacts]: Contacts,
  [RoutesEnum.About]: About,
  [RoutesEnum.Components]: Components,
  [RoutesEnum.Partners]: Partners,
  [RoutesEnum.OtherPage]: OtherPage,
};
