import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import { PhoneSVG } from '../svg';
import { ButtonHover } from './need-help.styles';
const CallButton: FC = () => (
  <a href="tel:+244 922 592 266">
    <ButtonHover
      border="none"
      bg="primary"
      width={['100%', '100%', '10.25rem']}
      flexDirection="row"
      aria-label="call-button"
      borderRadius="0.3rem"
      name="call-button"
    >
      <FlexView alignItems="center" height="3rem" justifyContent="center">
        <PhoneSVG width="0.6rem" height="0.8rem" fill="#fff" />
        <Text
          ml="M"
          variant="normal"
          fontSize="0.9rem"
          lineHeight="1.3rem"
          color="textInverted"
        >
          Ligue
        </Text>
      </FlexView>
    </ButtonHover>
  </a>
);

export default CallButton;
