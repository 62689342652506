import React, { FC } from 'react';
import Helmet from 'react-helmet';

import { getSettings } from './slider.data';
import { SlickSlider } from './slider.styles';
import { SliderProps } from './slider.types';

const Slider: FC<SliderProps> = ({ type, children }) => (
  <>
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
    </Helmet>
    <SlickSlider type={type} {...getSettings(type)}>
      {children}
    </SlickSlider>
  </>
);

export default Slider;
