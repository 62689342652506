import { useTheme } from '@emotion/react';
import React, { FC } from 'react';

import { Theme } from '../../design-system/light-theme';
import { FlexView, Text } from '../../elements';
import { PhoneSVG } from '../svg';

const CardEmergencyNumber: FC = () => {
  const theme = useTheme() as Theme;

  return (
    <FlexView
      zIndex={3}
      bg="primary"
      width={['70%', '70%', '45%']}
      minHeight="10rem"
      position={['relative', 'relative', 'absolute']}
      bottom={['2rem', '2rem', '3rem']}
      left={['unset', 'unset', '2rem']}
      alignSelf={['flex-end', 'flex-end', 'unset']}
      color="textInverted"
      flexDirection="column"
      pt="1.8rem"
      px="0.9rem"
      pb="3rem"
    >
      <Text variant="title5" fontSize="1.1rem" fontWeight="600">
        ATENDIMENTO AO CLIENTE
      </Text>

      <Text variant="normal" fontSize="0.9rem" mt="0.8rem" mb="1.1rem">
        Contacte-nos e compre o seu produto na loja mais próxima.
      </Text>
      <a href="tel:+244 922 592 266">
        <Text variant="title5" fontSize="1.2rem" fontWeight="600">
          <PhoneSVG
            width="1rem"
            height="1rem"
            fill={theme.colors.textInverted}
          />
          {'  '}
          (+244) 922 592 266
        </Text>
      </a>
    </FlexView>
  );
};
export default CardEmergencyNumber;
