import React, { FC } from 'react';

import { FlexView, Image, Text, View } from '../../elements';
import { CardProps } from './why-call.types';
const Form: FC<CardProps> = ({ body, cod, title, icon }) => (
  <FlexView
    flexDirection="column"
    width={['95vw', '95vw', '40%']}
    alignItems="center"
    position="relative"
    mb={['3rem', '3rem', 'unset']}
  >
    <FlexView left="0" position="absolute" height="100%" alignItems="center">
      <Text
        variant="normal"
        color="foreground"
        fontFamily="'Montserrat', sans-serif"
        fontSize={['8rem', '8rem', '9rem']}
        fontWeight="600"
      >
        {cod}
      </Text>
    </FlexView>
    <FlexView
      width="80%"
      minHeight="11.5rem"
      boxShadow="0px 4px 4px 0px #00000040"
      py="1.5rem"
      pl={['1.5rem', '1.5rem', 'unset']}
      position="relative"
      bg="textInverted"
      flexDirection={['column', 'column', 'row']}
    >
      <FlexView pl={['unset', 'unset', '1.5rem']}>{icon}</FlexView>
      <FlexView flexDirection="column" pl="S">
        <Text
          variant="large"
          fontSize="1rem"
          py="M"
          fontWeight="700"
          color="saturated"
        >
          {title}
        </Text>
        <Text variant="normal" my="auto" pr="5.5rem" color="textContrast">
          {body}
        </Text>
      </FlexView>
      <View width="3.1rem" height="7.813rem" right="1rem" position="absolute">
        <Image
          width="100%"
          height="100%"
          src={process.env.PUBLIC_URL + '/services-promo-bg.webp'}
          alt="Pontilhado usado como background numa secção"
        />
      </View>
    </FlexView>
  </FlexView>
);

export default Form;
