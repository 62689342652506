const space = {
  XS: '0.125rem',
  S: '0.25rem',
  M: '0.5rem',
  L: '1rem',
  XL: '2rem',
  XXL: '4rem',
  XXXL: '5rem',
};

export default space;
