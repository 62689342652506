/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTheme } from '@emotion/react';
import React, { FC, useState } from 'react';

import { Theme } from '../../design-system/light-theme';
import { FlexView } from '../../elements';
import { FacebookSVG, InstagramSVG } from '../svg';
import { SocialMidiasProps } from './social-midias.types';

const SocialMedias: FC<SocialMidiasProps> = ({ defaultColor }) => {
  const theme = useTheme() as Theme;
  const [FBColor, setFBColor] = useState(defaultColor);
  const [InstaColor, setInstaColor] = useState(defaultColor);

  const toogleColor = (
    setter: (value: React.SetStateAction<string>) => void,
    color: string
  ) => setter(color == defaultColor ? theme.colors.primary : defaultColor);
  return (
    <>
      <a
        aria-label="Facebook"
        href="https://www.facebook.com/angoelectrica1"
        target="_blank"
        rel="noreferrer"
      >
        <FlexView
          width="1.25rem"
          height="1.25rem"
          onMouseEnter={() => toogleColor(setFBColor, FBColor)}
          onMouseLeave={() => toogleColor(setFBColor, FBColor)}
        >
          <FacebookSVG width="100%" height="100%" fill={FBColor} />
        </FlexView>
      </a>
      <a
        aria-label="Instagram"
        href="https://www.instagram.com/angoelectrica__/"
        target="_blank"
        rel="noreferrer"
      >
        <FlexView
          width="1.25rem"
          height="1.25rem"
          onMouseEnter={() => toogleColor(setInstaColor, InstaColor)}
          onMouseLeave={() => toogleColor(setInstaColor, InstaColor)}
        >
          <InstagramSVG width="100%" height="100%" fill={InstaColor} />
        </FlexView>
      </a>
    </>
  );
};

export default SocialMedias;
