import React, { FC, useState } from 'react';

import { Container } from '../shared';
import Map from './map';
import { MAPS_LINKS } from './maps.helpers';
import OtherInfos from './other-infos';
const Maps: FC = () => {
  const [selectMap, setSelectMap] = useState(MAPS_LINKS.spaulo);

  return (
    <Container
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      pb={['XXL', 'XXL', '4rem']}
      pt={['M', 'M', '5rem']}
    >
      <Map currentMap={selectMap} />
      <OtherInfos setMap={setSelectMap} />
    </Container>
  );
};

export default Maps;
