import React, { FC } from 'react';

import { FlexView, Image, Text, View } from '../../elements';

const Facts: FC = () => (
  <FlexView flexDirection="column" mb={['3.25rem', '3.25rem', 'unset']}>
    <FlexView position="relative" alignItems="center" flexDirection="column">
      <Text
        variant="strong"
        color="secondary"
        fontWeight="500"
        width="100%"
        textAlign={['center', 'center', 'unset']}
      >
        Nossos Números
      </Text>
      <Text
        variant="title3"
        color="textInverted"
        fontSize={['1.5rem', '1.5rem', '2.25rem']}
        textAlign={['center', 'center', 'unset']}
      >
        Alguns Factos Importantes
      </Text>
      <View
        width="30%"
        height="100%"
        position="absolute"
        top={['-1rem', '-1rem', 'unset']}
      >
        <Image
          width="100%"
          height="100%"
          src={process.env.PUBLIC_URL + '/bg_marker.webp'}
          alt="Pontilhados para fundo de uma secção"
        />
      </View>
    </FlexView>
  </FlexView>
);

export default Facts;
