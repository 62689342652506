import React, { FC } from 'react';

import { FlexView } from '../../elements';
import CallButton from './call-button';
import Content from './content';
import HeaderText from './header-text';
import NeedHelpWrapper from './need-help-wrapper';
const NeedHelp: FC = () => (
  <NeedHelpWrapper>
    <FlexView
      bg="textInverted"
      flexDirection="column"
      width={['90vw', '90vw', '36.5rem']}
      minHeight={['auto', 'auto', '25rem']}
      px={['1rem', '1rem', '3.438rem']}
      justifyContent="center"
      py={['L', 'L', 'unset']}
    >
      <HeaderText />
      <Content />
      <CallButton />
    </FlexView>
  </NeedHelpWrapper>
);

export default NeedHelp;
