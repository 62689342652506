import React, { FC } from 'react';

import { FlexView } from '../../elements';
import { Container } from '../shared';
import CardImage from './card-image';
import SlideComments from './slide-comments';
import { COMMENTS_DEFAULT, FEATURE_DEFAULT } from './testimonial.data';
import TipsBar from './tips-bar';

const Testimonial: FC = () => (
  <FlexView
    flexDirection="column"
    bg={['saturated', 'saturated', 'textInverted']}
    mb={['auto', 'auto', '2rem']}
    position="relative"
    width="100vw"
  >
    <TipsBar features={FEATURE_DEFAULT} />
    <Container flexDirection="column">
      <SlideComments comments={COMMENTS_DEFAULT} />
      <CardImage />
    </Container>
  </FlexView>
);

export default Testimonial;
