import React, { FC, SVGAttributes } from 'react';

const HomeGroupSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 34" {...props}>
    <path
      d="M25.334 23.6673H22.0007V33.6673H17.0007V25.334H10.334V33.6673H5.33398V23.6673H2.00065L13.6673 13.6673L25.334 23.6673ZM7.00065 0.333984L13.6673 7.00065H12.0007V12.0007H8.66732V7.00065H5.33398V12.0007H2.00065V7.00065H0.333984L7.00065 0.333984ZM27.0007 2.00065L35.334 10.334H33.6673V17.0007H28.6673V12.0007H25.334V17.0007H22.5673L20.334 15.1173V10.334H18.6673L27.0007 2.00065Z"
      fill={props.fill || '#B30600'}
    />
  </svg>
);

export default HomeGroupSVG;
