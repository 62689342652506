import React, { FC } from 'react';

import { FlexView, Image, View } from '../../elements';
const ImageGroup: FC = () => (
  <FlexView
    width={['100%', '100%', '45%']}
    position="relative"
    height={['16.851rem', '25.851rem', '100%']}
  >
    <FlexView
      position="absolute"
      width={['92%', '92%', '100%']}
      height="80%"
      left={['8%', '8%', 'XXL']}
    >
      <Image
        src={process.env.PUBLIC_URL + '/electrician-with-helmet.webp'}
        width="100%"
        height="100%"
        alt="Electricista com um capacete"
      />
    </FlexView>
    <View position="absolute" bottom="0" width="60%" height="40%">
      <Image
        src={process.env.PUBLIC_URL + '/multimeter-with-inverser.webp'}
        width="100%"
        height="100%"
        alt="Um multímetro com inversor"
      />
    </View>
  </FlexView>
);

export default ImageGroup;
