import React, { FC, SVGAttributes } from 'react';

const LocationSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 13" {...props}>
    <path
      d="M5.00065 12.9999C4.15861 12.2817 3.37811 11.4942 2.66732 10.6459C1.60065 9.3719 0.333985 7.47457 0.333985 5.66657C0.333522 4.74321 0.60699 3.84046 1.11978 3.07258C1.63257 2.3047 2.36163 1.7062 3.2147 1.35283C4.06776 0.999456 5.00648 0.907092 5.91206 1.08743C6.81764 1.26776 7.64937 1.71269 8.30198 2.3659C8.73649 2.79847 9.08088 3.31292 9.31524 3.87948C9.5496 4.44604 9.66927 5.05345 9.66732 5.66657C9.66732 7.47457 8.40065 9.3719 7.33399 10.6459C6.62319 11.4942 5.84269 12.2816 5.00065 12.9999ZM5.00065 3.66657C4.47022 3.66657 3.96151 3.87728 3.58644 4.25235C3.21137 4.62742 3.00065 5.13613 3.00065 5.66657C3.00065 6.197 3.21137 6.70571 3.58644 7.08078C3.96151 7.45585 4.47022 7.66657 5.00065 7.66657C5.53109 7.66657 6.03979 7.45585 6.41487 7.08078C6.78994 6.70571 7.00065 6.197 7.00065 5.66657C7.00065 5.13613 6.78994 4.62742 6.41487 4.25235C6.03979 3.87728 5.53109 3.66657 5.00065 3.66657Z"
      fill={props.fill}
    />
  </svg>
);

export default LocationSVG;
