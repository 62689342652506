import React, { FC } from 'react';

import { FlexView } from '../../elements';
const ContentGroupWrapper: FC = ({ children }) => (
  <FlexView
    width={['100vw', '100vw', '45rem']}
    px="L"
    my="auto"
    zIndex={2}
    flexDirection="column"
    alignItems="center"
  >
    {children}
  </FlexView>
);

export default ContentGroupWrapper;
