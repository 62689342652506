import { useTheme } from '@emotion/react';
import React, { FC } from 'react';

import { Theme } from '../../design-system/light-theme';
import { FlexView, Text } from '../../elements';
import SocialMedias from '../social-midias';
const Follow: FC = () => {
  const theme = useTheme() as Theme;
  return (
    <FlexView flexDirection="column" px={['unset', 'unset', 'M']}>
      <Text variant="normal" fontWeight="600">
        SIGA-NOS:
      </Text>
      <FlexView width="8rem" justifyContent="space-between" mt="0.40rem">
        <SocialMedias defaultColor={theme.colors.saturated} />
      </FlexView>
    </FlexView>
  );
};
export default Follow;
