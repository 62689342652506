import styled from '@emotion/styled';
import { FC } from 'react';
import {
  background,
  border,
  color,
  compose,
  flexbox,
  layout,
  position,
  space,
  typography,
} from 'styled-system';

import { NavProps } from './nav.types';

const Nav: FC<NavProps> = styled.nav(
  compose(
    color,
    space,
    border,
    layout,
    position,
    typography,
    background,
    flexbox
  )
);

export default Nav;
