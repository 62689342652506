import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import ItemSlideWrapper from './item-slide-wrapper';
import { ItemSlideProps } from './slideshow.types';
const ItemSlide: FC<ItemSlideProps> = ({ picture, title, subTitle }) => (
  <ItemSlideWrapper picture={picture}>
    <FlexView zIndex={2} flexDirection="column">
      <Text
        variant="normal"
        color="textInverted"
        textAlign="center"
        fontSize={['0.75rem', '0.75rem', '1.125rem']}
      >
        {subTitle}
      </Text>
      <Text
        variant="title3"
        color="textInverted"
        textAlign="center"
        fontSize={['1.5rem', '1.5rem', '3.625rem']}
      >
        {title}
      </Text>
    </FlexView>
  </ItemSlideWrapper>
);

export default ItemSlide;
