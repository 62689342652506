import styled from '@emotion/styled';
import ReactSlick from 'react-slick';

import { SliderProps } from './slider.types';

export const SlickSlider = styled(ReactSlick)<SliderProps>`
  width: 100%;
  height: 95%;
  & > .slick-list > .slick-track {
    display: flex;
    align-items: center;
  }
  & > .slick-list > .slick-track > .slick-slide > div {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
  }
  & > .slick-arrow {
    display: ${(props) =>
      props.type === 'know-more-full' ? 'block' : 'none !important'};
    z-index: 2;
  }
  & > .slick-prev {
    left: ${(props) => (props.type === 'know-more-full' ? '0px' : 'unset')};
  }
  & > .slick-next {
    right: ${(props) => (props.type === 'know-more-full' ? '20px' : 'unset')};
  }
  & > .slick-dots {
    bottom: ${(props) => (props.type === 'comments' ? '0rem' : '-1.7rem')};
    text-align: ${(props) => (props.type === 'comments' ? 'left' : 'center')};
    padding-left: ${(props) =>
      props.type === 'comments' ? '11.8rem !important' : 'unset'};
  }
  & > .slick-dots > li {
    background: ${(props) =>
      props.type === 'hero'
        ? '#ab6463cf !important'
        : ['know-more', 'comments'].includes(props.type)
        ? '#C4C4C4 !important'
        : ''};
    border-radius: 0.7rem;
    font-size: 1rem;
    height: 0.7rem !important;
    width: 0.7rem !important;
  }
  & > .slick-dots > li > button {
    height: 100% !important;
    width: 100% !important;
  }
  & > .slick-dots > li > button:before {
    content: '' !important;
    height: 0.1rem !important;
    width: 0.1rem !important;
  }
  & > .slick-dots > .slick-active {
    background: ${(props) =>
      props.type === 'hero'
        ? '#ff2920 !important'
        : ['know-more', 'comments'].includes(props.type)
        ? '#000 !important'
        : ''};
  }
  @media only screen and (max-width: 832px) {
    & > .slick-dots {
      padding-left: ${(props) =>
        props.type === 'comments' ? '1.4rem !important' : 'unset'};
    }
    & > .slick-dots > li {
      height: ${(props) =>
        props.type === 'comments'
          ? '1.5rem !important'
          : props.type === 'know-more'
          ? '1.5rem !important'
          : '0.7rem !important'};
      width: ${(props) =>
        props.type === 'comments'
          ? '1.5rem !important'
          : props.type === 'know-more'
          ? '1.5rem !important'
          : '0.7rem !important'};
    }
  }
`;
