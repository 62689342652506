import { css } from '@emotion/react';

const title1 = css`
  font-size: 4.209rem;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
`;

const title2 = css`
  font-size: 3.157rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
`;

const title3 = css`
  font-size: 2.369rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
`;

const title4 = css`
  font-size: 1.777rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
`;

const title5 = css`
  font-size: 1.333rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -1.5%;
  text-transform: uppercase;
`;

const large = css`
  font-size: 1.333rem;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
`;

const normal = css`
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
`;

const strong = css`
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
`;

const small = css`
  font-size: 0.85rem;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
`;

export default {
  title1,
  title2,
  title3,
  title4,
  title5,
  large,
  normal,
  strong,
  small,
};
