import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Text } from '../../elements';
import ContentGroupWrapper from './content-group-wrapper';
import PartnersList from './parters-list';
const ContentGroup: FC = () => {
  const partners = useLocation();
  return (
    <ContentGroupWrapper>
      <Text
        variant="strong"
        color="primary"
        fontWeight="600"
        lineHeight="1.18rem"
        textAlign={['center', 'center', 'unset']}
      >
        Principais marcas
      </Text>
      <Text
        variant="title3"
        lineHeight={['2.25rem', '2.25rem', '3.37rem']}
        fontSize={['1.5rem', '1.5rem', '2.575rem']}
        mt="0.688rem"
        mb="1.55rem"
        textAlign={['center', 'center', 'unset']}
      >
        {['', 'Todas'].includes(partners.pathname.split('/brands/')[1])
          ? 'Parceiros'
          : partners.pathname.split('/brands/')[1]}
      </Text>
      <Text
        variant="normal"
        color="border"
        fontWeight="400"
        lineHeight="1.18rem"
        fontSize="1rem"
        textAlign={['justify', 'justify', 'unset']}
      >
        Trabalhamos com as melhores marcas no mercado.
      </Text>
      <PartnersList />
    </ContentGroupWrapper>
  );
};
export default ContentGroup;
