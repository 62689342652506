import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Routes, RoutesEnum } from '../../constants/routes';
import { Image } from '../../elements';
import { Container } from '../shared';
import NavList from './nav-list';
//;

const FullNavBar: FC = () => (
  <Container
    display={['none', 'none', 'flex']}
    alignItems="center"
    minHeight={['3.025rem', '3.025rem', '4.825rem']}
    justifyContent="space-between"
    width="100vw"
    py="1rem"
  >
    <Link aria-label="Página inicial" to={Routes[RoutesEnum.Home]}>
      <Image
        src={process.env.PUBLIC_URL + '/logo.webp'}
        width="12.25rem"
        height="100%"
        alt="Logotipo - AngoElectrica"
      />
    </Link>
    <NavList />
  </Container>
);

export default FullNavBar;
