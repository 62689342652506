import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Routes, RoutesEnum } from '../../constants/routes';
import { FlexView, Text } from '../../elements';
import { Container } from '../shared';
import { BreadcrumbProps } from './breadcrumb.types';
const Breadcrumb: FC<BreadcrumbProps> = ({ location, link }) => {
  return (
    <FlexView
      height={['5rem', '5rem', '6rem']}
      width="100vw"
      background={`url('${
        process.env.PUBLIC_URL + '/where-am-i.webp'
      }') no-repeat center center`}
      backgroundSize="cover"
      alignItems={['center', 'center', 'center']}
      backgroundPosition={['right', 'right', 'unset']}
      mb="XXL"
    >
      <Container
        flexDirection="row"
        height={['100%', '100%', 'unset']}
        alignItems="center"
        pl={['L', 'L', 'unset']}
      >
        <Link aria-label="Página inicial" to={Routes[RoutesEnum.Home]}>
          <Text variant="small" color="textInverted">
            Home&nbsp;/
          </Text>
        </Link>
        <Link aria-label="Página inicial" to={Routes[link || RoutesEnum.Home]}>
          <Text variant="small" color="textInverted">
            &nbsp;{location}
          </Text>
        </Link>
      </Container>
    </FlexView>
  );
};
export default Breadcrumb;
