import React, { FC } from 'react';

import { FlexView } from '../../elements';
import FullKnowMore from './full-know-more';
import MobileKnowMore from './mobile-know-more';

const KnowMore: FC = () => (
  <FlexView width="100vw" mb={['XL', 'XL', 'unset']}>
    <FullKnowMore />
    <MobileKnowMore />
  </FlexView>
);

export default KnowMore;
