import React, { FC } from 'react';

import { FlexView } from '../../elements';
import { Container } from '../shared';
import ContentGroup from './content-group';
import ImageGroup from './image-group';
const WhoWeAre: FC = () => (
  <Container
    height={['auto', 'auto', '34rem']}
    alignItems="center"
    flexDirection={['column', 'column', 'row']}
    justifyContent="center"
  >
    <FlexView
      width={['95%', '95%', '80%']}
      height="90%"
      flexDirection={['column-reverse', 'column-reverse', 'row']}
    >
      <ContentGroup />
      <ImageGroup />
    </FlexView>
  </Container>
);

export default WhoWeAre;
