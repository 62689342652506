import React, { FC, SVGAttributes } from 'react';

const CogsSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 22" {...props}>
    <path
      d="M13.4551 22H14.5098L14.5098 6.44531H15.5645V2.57812H14.5098V0H13.4551V2.57812H12.4004V6.44531H13.4551L13.4551 22ZM13.4551 3.86719H14.5098V5.15625H13.4551V3.86719Z"
      fill="black"
    />
    <path
      d="M0.0722656 6.44531H1.12695L1.12695 22H2.18164L2.18164 6.44531H3.23633L3.23633 2.57812H2.18164V0H1.12695V2.57812H0.0722656L0.0722656 6.44531ZM1.12695 3.86719H2.18164V5.15625H1.12695L1.12695 3.86719Z"
      fill="black"
    />
    <path
      d="M7.29102 22H8.3457V12.4609H9.40039L9.40039 8.59375H8.3457V0H7.29102V8.59375H6.23633L6.23633 12.4609H7.29102L7.29102 22ZM7.29102 9.88281H8.3457L8.3457 11.1719H7.29102V9.88281Z"
      fill="black"
    />
  </svg>
);

export default CogsSVG;
