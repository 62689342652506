import React, { FC } from 'react';
import { v4 } from 'uuid';

import { FlexView, Text } from '../../elements';
import { SERVICES } from '../know-more/know-more.data';
import { Item } from './item-menu';

const SideBar: FC = () => (
  <FlexView
    className="submenu-bar"
    width={['100%', '100%', '16.875rem']}
    height="100%"
    boxShadow={['unset', 'unset', '0px 0px 15px 1px rgba(123, 123, 123, 0.25)']}
    bg={['unset', 'unset', 'background']}
    pt="1.5rem"
    pl={['L', 'L', '2.313rem']}
    pr="L"
    pb={['1.5rem', '1.5rem', '8rem']}
    display={['none', 'none', 'block']}
    mr={['unset', 'unset', '6.938rem']}
  >
    <Text
      fontSize="1.5rem"
      lineHeight="2.25rem"
      fontWeight="600"
      mb="2.188rem"
      display={['none', 'none', 'block']}
    >
      Categorias
    </Text>
    <Item title="Todas" />
    <FlexView flexDirection={'column'}>
      {SERVICES.map((item) => (
        <Item title={item.description} key={v4()} />
      ))}
    </FlexView>
  </FlexView>
);

export default SideBar;
