import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { FC } from 'react';
import {
  background,
  border,
  color,
  compose,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
} from 'styled-system';

import { ViewProps } from './view.types';

const View: FC<ViewProps> = ({ as, hover, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const StyledView = styled((as as any) || 'div')(
    css({
      ...(hover && { transition: 'all 250ms ease-in-out', ':hover': hover }),
    }),
    compose(
      color,
      space,
      border,
      position,
      shadow,
      layout,
      typography,
      background
    ),
    system({
      cursor: true,
    })
  );
  // eslint-disable-next-line react/react-in-jsx-scope
  return <StyledView {...props} />;
};
export default View;
