import React, { FC, SVGAttributes } from 'react';

const CheckSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14" {...props}>
    <path
      d="M5.81189 13.4969C5.30095 13.4971 4.81093 13.294 4.44994 12.9324L0.332296 8.81628C-0.110765 8.37308 -0.110765 7.65464 0.332296 7.21144C0.775498 6.76838 1.49393 6.76838 1.93713 7.21144L5.81189 11.0862L16.0629 0.835225C16.5061 0.392164 17.2245 0.392164 17.6677 0.835225C18.1108 1.27843 18.1108 1.99686 17.6677 2.44006L7.17385 12.9324C6.81286 13.294 6.32284 13.4971 5.81189 13.4969Z"
      fill={props?.fill}
    />
  </svg>
);

export default CheckSVG;
