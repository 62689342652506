import React, { FC } from 'react';
import { v4 } from 'uuid';

import { FlexView } from '../../elements';
import { Container } from '../shared';
import Slider from '../slider';
import ItemSlidePartner from './item-slide';
import { BRANDS } from './partners.data';

const Partners: FC = () => (
  <FlexView width="100vw" my="XXL">
    <Container pt="1.5rem" pb={['1.5rem', '1.5rem', 'auto']}>
      <Slider type="partners">
        {BRANDS.sort(({ imgSrc }, b) => (imgSrc < b.imgSrc ? -1 : 1)).map(
          ({ imgSrc, link }) => (
            <a key={v4()} href={link || '/'} target="_blank" rel="noreferrer">
              <ItemSlidePartner
                width="10rem"
                height="7rem"
                picture={process.env.PUBLIC_URL + imgSrc}
              />
            </a>
          )
        )}
      </Slider>
    </Container>
  </FlexView>
);

export default Partners;
