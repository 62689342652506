import React, { FC } from 'react';

import { FlexView } from '../../elements';
const ContentGroupWrapper: FC = ({ children }) => (
  <FlexView
    width={['100vw', '100vw', '100%']}
    px={['M', 'M', 'L']}
    my="auto"
    pb={['4rem', '4rem', '8rem']}
    zIndex={2}
    flexDirection="column"
    alignItems="center"
  >
    {children}
  </FlexView>
);

export default ContentGroupWrapper;
