import { useTheme } from '@emotion/react';
import React, { FC } from 'react';

import { Theme } from '../../design-system/light-theme';
import { FlexView, Text, View } from '../../elements';
import { Container } from '../shared';
import { ClockSVG, PhoneSVG } from '../svg';
import ItemTopBar from '../top-bar/item-top-bar';
import { DESCRIPTION_ANGOELECTRICA } from './footer.data';

const InfoPlace: FC = () => {
  const theme = useTheme() as Theme;

  return (
    <FlexView flexDirection="column" width={['100%', '100%', '25.063rem']}>
      <Text
        display={['none', 'none', 'flex']}
        variant="normal"
        color="semiSaturated"
        fontWeight="400"
      >
        {DESCRIPTION_ANGOELECTRICA}
      </Text>
      <Container flexDirection="column" mt="2.5rem">
        <ItemTopBar
          description="São Paulo - 928 689 936 • Viana - 935 174 070 • Via Expresso (Benfica) - 932 223 159"
          color={theme.colors.semiSaturated}
          icon={
            <PhoneSVG
              width="1.5rem"
              height="1.5rem"
              fill={theme.colors.secondary}
            />
          }
        />
        <View my={['1rem', '1rem', '1.938rem']}>
          <ItemTopBar
            description="Seg - Sex: 8h00 - 12h30 e 13h30 - 17h00 | Sáb: 8h00 - 12h00"
            color={theme.colors.semiSaturated}
            icon={
              <ClockSVG
                width="1rem"
                height="1rem"
                fill={theme.colors.secondary}
              />
            }
          />
        </View>
      </Container>
    </FlexView>
  );
};

export default InfoPlace;
