import React, { FC } from 'react';

import { Container } from '../shared';
import ContentGroup from './content-group';
const SenMessage: FC = () => (
  <Container
    height={['auto', 'auto', '34rem']}
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    pb={['XXL', 'XXL', 'unset']}
    pt={['XL', 'XL', 'unset']}
  >
    <ContentGroup />
  </Container>
);

export default SenMessage;
