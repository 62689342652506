import React, { FC } from 'react';

import {
  About,
  Breadcrumb,
  Footer,
  KnowMore,
  Layout,
  Maps,
  NavBar,
  NeedHelp,
  Partners,
  SendMessage,
  SlideShow,
  Testimonial,
  TopBar,
  WhoWeAre,
  WhyCall,
} from '../components';
import { Text, View } from '../elements';

const Components: FC = () => (
  <Layout pageTitle="Início">
    <TopBar />
    <NavBar />
    <Breadcrumb location="Sobre" />
    <SlideShow />
    <About />
    <KnowMore />
    <Testimonial />
    <Partners />
    <NeedHelp />
    <Footer />
    <WhoWeAre />
    <SendMessage />
    <Maps />
    <WhyCall />
    <View
      bg="foreground"
      p="XXXL"
      borderRadius="S"
      borderTopRightRadius="0"
      borderTopLeftRadius="0"
    >
      <h1>Home</h1>
      <Text variant="title1" as="h1">
        Titulo 1
      </Text>
      <Text variant="title2" as="h2">
        Titulo 2
      </Text>
      <Text variant="title3" as="h3">
        Titulo 3
      </Text>
      <Text variant="title4" as="h4">
        Titulo 4
      </Text>
      <Text variant="title5" as="h5">
        Titulo 5
      </Text>
      <Text variant="large">Large</Text>
      <Text variant="strong">Strong</Text>
      <Text variant="normal">Normal</Text>
      <Text variant="small">small</Text>
    </View>
  </Layout>
);

export default Components;
