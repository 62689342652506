import React, { FC } from 'react';

import { FlexView } from '../../elements';
import FullNavBar from './full-nav-bar';
import MobileNavBar from './mobile-nav-bar';
const NavBar: FC = () => (
  <FlexView
    position="sticky"
    top="0"
    width="100vw"
    bg={'background'}
    minHeight={['3.025rem', '3.025rem', '4.825rem']}
    px={['L', 'L', 'M']}
    alignItems="center"
    boxShadow="0 0 1rem rgba(0,0,0,.1)"
    zIndex={99}
    flexDirection="column"
  >
    <FullNavBar />
    <MobileNavBar />
  </FlexView>
);

export default NavBar;
