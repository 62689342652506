import styled from '@emotion/styled';
import { FC } from 'react';
import {
  background,
  border,
  color,
  compose,
  flexbox,
  layout,
  position,
  space,
  typography,
} from 'styled-system';

import { UlProps } from './ul.types';

const Ul: FC<UlProps> = styled.ul(
  compose(
    color,
    space,
    border,
    layout,
    position,
    typography,
    background,
    flexbox
  )
);

export default Ul;
