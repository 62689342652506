import React, { FC } from 'react';

import { NeedHelp, PageWrapper, WhoWeAre, WhyCall } from '../components';
import { RoutesEnum } from '../constants/routes';

const About: FC = () => (
  <PageWrapper pageTitle="Sobre" description="Sobre" link={RoutesEnum.About}>
    <WhoWeAre />
    <WhyCall />
    <NeedHelp />
  </PageWrapper>
);

export default About;
