import { useTheme } from '@emotion/react';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { Routes, RoutesEnum } from '../../constants/routes';
import { Theme } from '../../design-system/light-theme';
import { Button, FlexView, Image } from '../../elements';
import { Container } from '../shared';
import { CartSVG, MenuSVG } from '../svg';
import ItemTopBar from '../top-bar/item-top-bar';
import NavList from './nav-list';

const MobileNavBar: FC = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const theme = useTheme() as Theme;

  return (
    <>
      <Container
        display={['flex', 'flex', 'none']}
        alignItems="center"
        minHeight={['3.025rem', '3.025rem', '4.825rem']}
        justifyContent="space-between"
        width="100vw"
        bg="background"
        py="1rem"
      >
        <Link aria-label="Página inicial" to={Routes[RoutesEnum.Home]}>
          <Image
            height="100%"
            src={process.env.PUBLIC_URL + '/logo.webp'}
            width="6.25rem"
            alt="Logotipo - AngoElectrica"
          />
        </Link>
        <FlexView display="flex" alignItems="center">
          <FlexView display="none">
            <ItemTopBar
              color={theme.colors.primary}
              description="3"
              icon={
                <CartSVG width="1rem" height="1rem" fill={theme.colors.text} />
              }
              badge
            />
          </FlexView>
          <Button
            ml="L"
            border="none"
            bg="transparent"
            height="3rem"
            name="navbar-button"
            aria-label="navbar"
            p="M"
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            <MenuSVG width="1rem" height="100%" />
          </Button>
        </FlexView>
      </Container>
      <Container
        display={[
          toggleMenu ? 'flex' : 'none',
          toggleMenu ? 'flex' : 'none',
          'none',
        ]}
        py="M"
      >
        <NavList />
      </Container>
    </>
  );
};

export default MobileNavBar;
