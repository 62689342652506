import { useTheme } from '@emotion/react';
import React, { FC } from 'react';
import { v4 } from 'uuid';

import { Theme } from '../../design-system/light-theme/index';
import { FlexView } from '../../elements';
import { Container } from '../shared';
import { CartSVG, ClockSVG, LocationSVG } from '../svg';
import PhoneSVG from '../svg/phone';
import ItemAddress from './item-address';
import ItemTopBar from './item-top-bar';
import { ADDRESS } from './top-bar.data';

const FullTopBar: FC = () => {
  const theme = useTheme() as Theme;

  return (
    <Container
      justifyContent="space-between"
      alignItems="center"
      minHeight="2.188rem"
      display={['none', 'none', 'flex']}
      py="1rem"
    >
      <FlexView
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <ItemTopBar
          description="Seg - Sex: 8h00 - 12h30 e 13h30 - 17h00 | Sáb: 8h00 - 12h00"
          color={theme.colors.semiSaturated}
          icon={
            <ClockSVG width="1rem" height="1rem" fill={theme.colors.primary} />
          }
        />
        <FlexView justifyContent="space-between">
          {ADDRESS.map((item) => (
            <ItemAddress address={item.address} phone={item.phone} key={v4()} />
          ))}
        </FlexView>
      </FlexView>
      <FlexView display="none">
        <ItemTopBar
          description="São Paulo - 928 689 936 • Viana - 935 174 070 • Via Expresso (Benfica) - 932 223 159"
          color={theme.colors.semiSaturated}
          icon={
            <PhoneSVG
              width="1.2rem"
              height="1.2rem"
              fill={theme.colors.secondary}
            />
          }
        />
        <a href="tel:+244 928 689 936">
          <ItemTopBar
            description="(+244) 928 689 936"
            color={theme.colors.secondary}
            icon={
              <LocationSVG
                width="1rem"
                height="1rem"
                fill={theme.colors.textInverted}
              />
            }
            space="M"
          />
        </a>
        <FlexView display="none">
          <ItemTopBar
            color={theme.colors.secondary}
            description="3"
            icon={
              <CartSVG
                width="1rem"
                height="1rem"
                fill={theme.colors.textInverted}
              />
            }
            badge
          />
        </FlexView>
      </FlexView>
    </Container>
  );
};
export default FullTopBar;
