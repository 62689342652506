import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Routes, RoutesEnum } from '../../constants/routes';
import { FlexView, Image, Text, Ul } from '../../elements';
import FeatureCheck from './features-check';
import { DESCRIPTION_ANGOELECTRICA } from './footer.data';

const Links: FC = () => (
  <FlexView flexDirection="column" mb={['2rem', '2rem', 'unset']}>
    <FlexView
      width="10.813rem"
      height="3.375rem"
      mb="1.688rem"
      alignSelf={['center', 'center', 'unset']}
    >
      <Link aria-label="Página inicial" to={Routes[RoutesEnum.Home]}>
        <Image
          src={process.env.PUBLIC_URL + '/logo-white.webp'}
          width="100%"
          height="100%"
          alt="Logotipo alternativo na cor branca"
        />
      </Link>
    </FlexView>
    <Text
      display={['flex', 'flex', 'none']}
      variant="normal"
      color="semiSaturated"
      fontWeight="400"
      textAlign="center"
      my="1.5rem"
    >
      {DESCRIPTION_ANGOELECTRICA}
    </Text>
    <Ul>
      <FeatureCheck description="Início" link={RoutesEnum.Home} />
      <FeatureCheck description="Sobre" link={RoutesEnum.About} />
      <FeatureCheck description="Marcas" link={RoutesEnum.Partners} />
      <FeatureCheck description="Contactos" link={RoutesEnum.Contacts} />
    </Ul>
  </FlexView>
);

export default Links;
