import React, { FC } from 'react';
import { v4 } from 'uuid';

import { FlexView } from '../../elements';
import { Container } from '../shared';
import Facts from './facts';
import Feature from './feature';
import { TipsBarProps } from './testimonial.types';

const TipsBar: FC<TipsBarProps> = ({ features }) => (
  <FlexView bg="saturated" height={['auto', 'auto', '18rem']} width="100%">
    <Container
      flexDirection={['column', 'column', 'row']}
      height="100%"
      pt={['3.8rem', '3.8rem', '4.6rem']}
      pl={['unset', 'unset', '8rem']}
    >
      <Facts />
      <FlexView
        flexDirection={['column', 'column', 'row']}
        color="textInverted"
        width={['100%', '100%', '80%']}
        justifyContent="space-evenly"
      >
        {features.map((feat) => (
          <Feature
            total={feat.total}
            description={feat.description}
            key={v4()}
          />
        ))}
      </FlexView>
    </Container>
  </FlexView>
);

export default TipsBar;
