import React, { FC } from 'react';

import { FlexView } from '../../elements';
import { Container } from '../shared';
import ContentGroup from './content-group';
import ImageGroup from './image-group';
const About: FC = () => (
  <Container
    height={['auto', 'auto', '34rem']}
    alignItems="center"
    flexDirection={['column', 'column', 'row']}
    justifyContent="center"
    pb={['XXL', 'XXL', 'unset']}
    pt={['XL', 'XL', 'unset']}
  >
    <FlexView
      width={['95%', '95%', '80%']}
      height="90%"
      flexDirection={['column', 'column', 'row']}
    >
      <ImageGroup />
      <ContentGroup />
    </FlexView>
  </Container>
);

export default About;
