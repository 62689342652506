import React, { FC } from 'react';

import { FlexView, Image } from '../../elements';
const ImageGroup: FC = () => (
  <FlexView
    width={['100%', '100%', '40%']}
    position="relative"
    height={['16.851rem', '25.851rem', '100%']}
    alignSelf="flex-end"
  >
    <FlexView
      position="absolute"
      width={['92%', '92%', '100%']}
      height="80%"
      left={['8%', '8%', 'unset']}
    >
      <Image
        src={process.env.PUBLIC_URL + '/who-we-are-1.webp'}
        width="100%"
        height="100%"
        alt="Uma Mulher com capacete fazendo algumas medições"
      />
    </FlexView>
    <FlexView
      width="60%"
      height={['50%', '50%', '30%']}
      position="absolute"
      bottom={['-5%', '-5%', '5%']}
      right={['0', '0', '-1.4rem']}
      left={['0', '0', 'unset']}
    >
      <Image
        src={process.env.PUBLIC_URL + '/who-we-are-2.webp'}
        width="100%"
        height="100%"
        alt="Uma mão alternando um quadro electrico"
      />
    </FlexView>
  </FlexView>
);

export default ImageGroup;
