import React, { FC } from 'react';

import { FlexView, Image } from '../../elements';
const Thunder: FC = () => (
  <FlexView position="absolute" height="6.688rem">
    <Image
      src={process.env.PUBLIC_URL + '/rain.webp'}
      width="100%"
      height="100%"
      alt="Simbolo de trovão"
    />
  </FlexView>
);

export default Thunder;
