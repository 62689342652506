import styled from '@emotion/styled';

import { FlexView } from '../../elements';

const Container = styled(FlexView)`
  width: 100%;
  margin: auto;
  max-width: 1240px;
`;

export default Container;
