import React, { FC, SVGAttributes } from 'react';

const CallSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38" {...props}>
    <path
      d="M33.2494 19.3483C33.2494 10.6558 26.5044 4.75 18.9994 4.75C11.5735 4.75 4.74935 10.5292 4.74935 19.4433C3.79935 19.9817 3.16602 20.995 3.16602 22.1667V25.3333C3.16602 27.075 4.59102 28.5 6.33268 28.5H7.91602V18.8417C7.91602 12.7142 12.8719 7.75833 18.9994 7.75833C25.1269 7.75833 30.0827 12.7142 30.0827 18.8417V30.0833H17.416V33.25H30.0827C31.8244 33.25 33.2494 31.825 33.2494 30.0833V28.1517C34.1835 27.6608 34.8327 26.695 34.8327 25.555V21.9133C34.8327 20.805 34.1835 19.8392 33.2494 19.3483Z"
      fill="#B30600"
    />
    <path
      d="M14.2493 22.1667C15.1238 22.1667 15.8327 21.4578 15.8327 20.5833C15.8327 19.7089 15.1238 19 14.2493 19C13.3749 19 12.666 19.7089 12.666 20.5833C12.666 21.4578 13.3749 22.1667 14.2493 22.1667Z"
      fill="#B30600"
    />
    <path
      d="M23.7493 22.1667C24.6238 22.1667 25.3327 21.4578 25.3327 20.5833C25.3327 19.7089 24.6238 19 23.7493 19C22.8749 19 22.166 19.7089 22.166 20.5833C22.166 21.4578 22.8749 22.1667 23.7493 22.1667Z"
      fill="#B30600"
    />
    <path
      d="M28.4993 17.4642C28.1217 15.2382 26.9688 13.2176 25.2446 11.76C23.5204 10.3024 21.3362 9.50185 19.0784 9.5C14.2809 9.5 9.11926 13.4742 9.53093 19.7125C11.4834 18.9135 13.2078 17.6439 14.5505 16.0167C15.8931 14.3895 16.8122 12.4553 17.2259 10.3867C19.3001 14.5508 23.5593 17.4167 28.4993 17.4642Z"
      fill="#B30600"
    />
  </svg>
);

export default CallSVG;
