import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
const HeaderText: FC = () => (
  <FlexView
    borderLeft="0.3rem solid"
    borderColor="primary"
    width="100%"
    px="1rem"
    py="L"
  >
    <Text variant="title3" fontSize={['1rem', '1rem', '2rem']}>
      <Text as="span" color="primary">
        Precisa de algum
      </Text>{' '}
      material eléctrico?
    </Text>
  </FlexView>
);

export default HeaderText;
