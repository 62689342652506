import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import Feature from './feature';
import Follow from './follow';
import { MAPS_LINKS } from './maps.helpers';
import { OtherInfoProps } from './maps.types';
const OtherInfos: FC<OtherInfoProps> = ({ setMap }) => (
  <FlexView
    width={['95%', '95%', '80%']}
    flexDirection={['column', 'column', 'row']}
  >
    <FlexView flexDirection="column" width={['100%', '100%', '20%']}>
      <Text variant="strong" color="primary" fontWeight="600">
        CONHEÇA-NOS
      </Text>
      <Text
        fontSize="1.2rem"
        fontWeight="600"
        fontFamily="'Montserrat', sans-serif"
        lineHeight="2rem"
      >
        Precisa de algum produto? Fale connosco.
      </Text>
      <Text variant="small" color="textContrast" mt="1rem">
        Onde o nosso serviço, ilumina o seu futuro
      </Text>
    </FlexView>
    <FlexView
      flexDirection="column"
      justifyContent="space-between"
      width={['100%', '100%', '40%']}
      ml={['unset', 'unset', 'L']}
    >
      <FlexView cursor="pointer" onClick={() => setMap(MAPS_LINKS.spaulo)}>
        <Feature
          title="Loja 1"
          location="São Paulo"
          phone="(+244) 928 689 936"
          email="loja.saopaulo@angoelectrica.com"
        />
      </FlexView>
      <FlexView cursor="pointer" onClick={() => setMap(MAPS_LINKS.viana)}>
        <Feature
          title="Loja 2"
          location="Viana"
          phone="(+244) 935 174 070"
          email="loja.viana@angoelectrica.com"
        />
      </FlexView>
      <FlexView cursor="pointer" onClick={() => setMap(MAPS_LINKS.benfica)}>
        <Feature
          title="Loja 3"
          location="Via Expresso (Benfica)"
          phone="(+244) 932 223 159"
          email="loja.viaexpresso@angoelectrica.com"
        />
      </FlexView>
    </FlexView>
    <FlexView flexDirection="column" width={['100%', '100%', '30%']}>
      <Feature
        title={'HORÁRIO DE ATENDIMENTO'}
        location={'Segunda - Sexta: 8h00 - 12h30 e 13h30 - 17h00'}
        phone={'Sábado: 8h00 - 12h00'}
      />
      <Follow />
    </FlexView>
  </FlexView>
);

export default OtherInfos;
