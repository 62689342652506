import React, { FC } from 'react';

import { Container } from '../shared';
import ContentGroup from './content-group';
const WhyCall: FC = () => (
  <Container
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    pb={['unset', 'unset', 'XXL']}
    pt={['XXL', 'XXL', 'XXL']}
  >
    <ContentGroup />
  </Container>
);

export default WhyCall;
