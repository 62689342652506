export const BRANDS = [
  {
    link: 'https://new.abb.com/pt',
    imgSrc: '/marcas/partner-abb.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.efapel.pt/pt',
    imgSrc: '/marcas/partner-efapel.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.aqua.it/en/',
    imgSrc: '/marcas/partner-aqua.webp',
    services: 'Hidraúlica',
  },
  {
    link: 'https://www.duralamp.it/en',
    imgSrc: '/marcas/partner-dura.webp',
    services: 'Iluminação',
  },
  {
    link: 'https://www.elettrocanali.com/es',
    imgSrc: '/marcas/partner-ec-elettro-canali.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.legrand.pt/',
    imgSrc: '/marcas/partner-legrand.webp',
    services: 'Electricidade',
  },
  // { link: '', imgSrc: '/marcas/partner-fimartel.webp' },
  {
    link: 'https://www.pentax-pumps.it/foras/pt-pt/',
    imgSrc: '/marcas/partner-foras.webp',
    services: 'Hidraúlica',
  },
  {
    link: 'https://www.generalcable.com/eu/pt',
    imgSrc: '/marcas/partner-general-cable.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.golmar.es/',
    imgSrc: '/marcas/partner-golmar.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.heliroma.pt',
    imgSrc: '/marcas/partner-heliroma.webp',
    services: 'Hidraúlica',
  },
  {
    link: 'https://www.italtecnica.com/',
    imgSrc: '/marcas/partner-italtecnica.webp',
    services: 'Hidraúlica',
  },
  {
    link: 'https://www.normalux.com/pt/inicio',
    imgSrc: '/marcas/partner-normalux.webp',
    services: 'Iluminação',
  },
  {
    link: 'https://fnpgroup.es/productos/ventilacion/',
    imgSrc: '/marcas/partner-novovent.webp',
    services: 'Ventilação',
  },
  {
    link: ' https://www.ledvance.pt/profissional/empresa',
    imgSrc: '/marcas/partner-osram.webp',
    services: 'Iluminação',
  },
  {
    link: 'https://www.pecol.pt/',
    imgSrc: '/marcas/partner-pecol.webp',
    services: 'Ferramentas',
  },
  {
    link: 'https://www.se.com/pt/pt/all-products/',
    imgSrc: '/marcas/partner-scheneider.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.soflight.com/',
    imgSrc: '/marcas/partner-soflight.webp',
    services: 'Iluminação',
  },
  {
    link: 'https://www.sylvania-lighting.com/en-int/',
    imgSrc: '/marcas/partner-sylvania.webp',
    services: 'Iluminação',
  },
  {
    link: 'https://www.vossloh-schwabe.com/en',
    imgSrc: '/marcas/partner-vs.svg',
    services: 'Iluminação',
  },
  {
    link: 'https://www.technoelectric.it/gb/',
    imgSrc: '/marcas/partner-technoelectric.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://avel.eu/new/',
    imgSrc: '/marcas/partner-avel.svg',
    services: 'Electricidade',
  },
  {
    link: 'https://www.tev.pt/',
    imgSrc: '/marcas/partner-tev2.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.findernet.com/pt/portugal/',
    imgSrc: '/marcas/partner-finder.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.wago.com/global/',
    imgSrc: '/marcas/partner-wago.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.haupa.com',
    imgSrc: '/marcas/partner-haupa.webp',
    services: 'Ferramentas',
  },
  {
    link: 'https://www.einhell.com',
    imgSrc: '/marcas/partner-einhell.webp',
    services: 'Ferramentas',
  },
  {
    link: 'https://duravent.com/',
    imgSrc: '/marcas/partner-duravent.webp',
    services: 'Ventilação',
  },
  {
    link: 'https://www.orbis.es/inicio',
    imgSrc: '/marcas/partner-orbis.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.obo.pt/',
    imgSrc: '/marcas/partner-obo.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.teka.com/pt-pt/',
    imgSrc: '/marcas/partner-teka.webp',
    services: 'Electricidade',
  },
  {
    link: 'http://www.indelague.com/pt',
    imgSrc: '/marcas/partner-indelague.webp',
    services: 'Iluminação',
  },
  {
    link: 'https://sofamel.com/',
    imgSrc: '/marcas/partner-sofamel.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://sp-portugal.pt/',
    imgSrc: '/marcas/partner-sp.webp',
    services: 'Ventilação',
  },
  {
    link: 'http://www.quadroviseu.com/',
    imgSrc: '/marcas/partner-quadro-viseu.webp',
    services: 'Electricidade',
  },
  {
    link: '/',
    imgSrc: '/marcas/partner-fonteluz.webp',
    services: 'Iluminação',
  },
  {
    link: 'https://www.cluce.it/',
    imgSrc: '/marcas/partner-cluce.webp',
    services: 'Iluminação',
  },
  {
    link: 'https://www.junkers-bosch.pt/pt/pt/inicio/',
    imgSrc: '/marcas/partner-junkers.webp',
    services: 'Hidraúlica',
  },
  {
    link: 'https://at3w.com/',
    imgSrc: '/marcas/partner-aplicacione-tecnologicas.webp',
    services: 'Para-Raios',
  },
  {
    link: 'https://www.mo-el.com/',
    imgSrc: '/marcas/partner-moel.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.tekox.es/es/91',
    imgSrc: '/marcas/partner-tekox.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://wd40.pt/',
    imgSrc: '/marcas/partner-wd-40.webp',
    services: 'Ferramentas',
  },
  {
    link: 'https://www.uniks.it/',
    imgSrc: '/marcas/partner-uniks.webp',
    services: 'Electricidade',
  },
  {
    link: 'https://www.prysmiangroup.com/en',
    imgSrc: '/marcas/partner-prysmian.webp',
    services: 'Electricidade',
  },
];
