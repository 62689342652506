import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import { FeaturesProps } from './maps.types';

const Feature: FC<FeaturesProps> = ({ title, location, phone, email }) => (
  <FlexView
    flexDirection="column"
    width={['100%', '100%', '25rem']}
    mt={['1rem', '1rem', 'unset']}
    px={['unset', 'unset', 'M']}
    mb={['1rem', '1rem', '1rem']}
  >
    <Text variant="normal" fontWeight="600" mb="S">
      {title}
    </Text>
    <Text variant="small" color="textContrast" mb="XS">
      {title != 'HORÁRIO DE ATENDIMENTO' && (
        <Text as="span" fontWeight="bold">
          Localização:{' '}
        </Text>
      )}
      {location}
    </Text>
    <a href={`tel:${phone}`}>
      <Text variant="small" color="textContrast">
        {title != 'HORÁRIO DE ATENDIMENTO' && (
          <Text as="span" fontWeight="bold">
            Tel:{' '}
          </Text>
        )}
        {phone}
      </Text>
    </a>
    <Text variant="small" color="textContrast">
      {title != 'HORÁRIO DE ATENDIMENTO' && (
        <Text as="span" fontWeight="bold">
          Email Loja:{' '}
        </Text>
      )}
      <a href={`mailto:${email}`}>{email}</a>
    </Text>
  </FlexView>
);

export default Feature;
