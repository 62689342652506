import React, { FC } from 'react';

import { FlexView } from '../../elements';
import Content from './content';
import { CommentsProps } from './testimonial.types';

const Comments: FC<CommentsProps> = ({ content, title }) => (
  <FlexView
    mt={['unset', 'unset', '3rem']}
    width="80%"
    pb={['1.4rem', '1.4rem', '1.6rem']}
  >
    <Content content={content} title={title} />
  </FlexView>
);

export default Comments;
