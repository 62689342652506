import { useTheme } from '@emotion/react';
import React, { FC } from 'react';

import { Theme } from '../../design-system/light-theme';
import { FlexView, Text } from '../../elements';
import { CheckSVG } from '../svg';
import { FeaturesProps } from './about.types';
const FeatureCheck: FC<FeaturesProps> = ({ description }) => {
  const theme = useTheme() as Theme;
  return (
    <FlexView alignItems="center" mb="0.25rem" display="none">
      <CheckSVG
        width="1.125rem"
        height="1.125rem"
        fill={theme.colors.primary}
      />
      <Text
        variant="small"
        ml="S"
        lineHeight="1rem"
        letterSpacing="0.05rem"
        color="textContrast"
      >
        {description}
      </Text>
    </FlexView>
  );
};

export default FeatureCheck;
