import styled from '@emotion/styled';
import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  typography,
} from 'styled-system';

import { ButtonProps } from './button.types';

const Button = styled.button<ButtonProps>(
  color,
  space,
  border,
  layout,
  flexbox,
  position,
  typography,
  background
);

export default Button;
