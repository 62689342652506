/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import { Input } from '../../elements';
import { InputTextArea } from './send-a-message.style';
import { FormProps } from './send-a-message.types';
const Field: FC<FormProps> = ({ placeholder, type, setField, value }) =>
  ['text', 'email'].includes(type) ? (
    <Input
      width="100%"
      height="2.313rem"
      border="0.06rem solid"
      borderColor="#E9E9EB"
      borderRadius="0.31rem"
      my={['M', 'M', 'M']}
      p="M"
      placeholder={placeholder}
      onChange={(e: { target: { value: any } }) => setField(e.target.value)}
      value={value}
      type={type}
    />
  ) : (
    <InputTextArea
      rows={5}
      width="100%"
      border="0.06rem solid"
      borderColor="#E9E9EB"
      borderRadius="0.31rem"
      my={['M', 'M', 'M']}
      p="M"
      placeholder={placeholder}
      onChange={(e: { target: { value: any } }) => setField(e.target.value)}
      value={value}
    ></InputTextArea>
  );

export default Field;
