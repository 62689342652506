import React, { FC } from 'react';
import { v4 } from 'uuid';

import { FlexView } from '../../elements';
import Slider from '../slider';
import Item from './item';
import { SERVICES } from './know-more.data';

const FullKnowMore: FC = () => (
  <FlexView
    height="25.625rem"
    width="100%"
    flexDirection="row"
    display={['none', 'none', 'flex']}
  >
    <Slider type="know-more-full">
      {SERVICES.map((item) => (
        <Item description={item.description} image={item.image} key={v4()} />
      ))}
    </Slider>
  </FlexView>
);

export default FullKnowMore;
