import React, { FC } from 'react';

import { FlexView, Image } from '../../elements';

const ItemGrid: FC<{ picture: string; link: string }> = ({ picture, link }) => (
  <FlexView
    width={['80%', '80%', '10rem']}
    height="6rem"
    mt={['XXL', 'XXL', 'L']}
    mx="auto"
    justifyContent="center"
    alignItems="center"
  >
    <a href={link || '/'} target="_blank" rel="noreferrer">
      <Image
        src={picture}
        width={['100%', '100%', '10rem']}
        height="6rem"
        objectFit="contain"
        alt="SlideShow imagens"
      />
    </a>
  </FlexView>
);

export default ItemGrid;
