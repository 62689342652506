import React, { FC, useEffect } from 'react';

import { FlexView } from '../../elements';
import { Breadcrumb, Footer, Layout, NavBar, TopBar } from '../';
import { PageWrapperProps } from './page-wrapper.types';

const PageWrapper: FC<PageWrapperProps> = ({
  pageTitle = '',
  description,
  link,
  children,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Layout pageTitle={pageTitle}>
      <TopBar />
      <NavBar />
      {description && <Breadcrumb location={description} link={link} />}
      <FlexView minHeight="100vh" flexDirection="column">
        {children}
      </FlexView>
      <Footer />
    </Layout>
  );
};

export default PageWrapper;
