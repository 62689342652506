import React, { FC, SVGAttributes } from 'react';

const MenuSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" {...props}>
    <line y1="1" x2="24" y2="1" stroke="black" strokeWidth="2" />
    <line x1="12" y1="8" x2="24" y2="8" stroke="black" strokeWidth="2" />
    <line y1="15" x2="24" y2="15" stroke="black" strokeWidth="2" />
  </svg>
);

export default MenuSVG;
