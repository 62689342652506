import React, { FC } from 'react';
import { v4 } from 'uuid';

import { FlexView } from '../../elements';
import Slider from '../slider';
import Item from './item';
import { SERVICES } from './know-more.data';

const MobileKnowMore: FC = () => (
  <FlexView
    height={['28.625rem', '28.625rem', '25.625rem']}
    flexDirection="row"
    width="100%"
    display={['flex', 'flex', 'none']}
  >
    <Slider type="know-more">
      {SERVICES.map((item) => (
        <Item description={item.description} image={item.image} key={v4()} />
      ))}
    </Slider>
  </FlexView>
);

export default MobileKnowMore;
