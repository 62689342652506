import React, { FC, SVGAttributes } from 'react';

const LeftArrowSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 8" {...props}>
    <path
      d="M0.395217 0.604795L3.79043 4L0.395217 7.39521V0.604795Z"
      fill="#B30600"
    />
  </svg>
);

export default LeftArrowSVG;
