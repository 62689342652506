import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import { TitleContentProps } from './testimonial.types';

const TitleContent: FC<TitleContentProps> = ({ title }) => (
  <FlexView>
    <FlexView
      flexDirection="column"
      mt={['0.8rem', '0.8rem', 'unset']}
      mr={['0.6rem', '0.6rem', 'unset']}
    >
      <Text
        variant="strong"
        color="primary"
        fontSize={['0.73rem', '0.73rem', '1rem']}
      >
        Dicas rápidas
      </Text>
      <Text
        variant="title3"
        color="saturated"
        fontSize={['1.3rem', '1.3rem', '2.25rem']}
        fontWeight="600"
      >
        {title}
      </Text>
    </FlexView>
  </FlexView>
);

export default TitleContent;
