import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { FlexView } from '../../elements';
import Field from './field';
import { ButtonStyled } from './send-a-message.style';
const Form: FC = () => {
  const [fullName, setFullName] = useState('');
  const [message, setMessage] = useState('');
  return (
    <form>
      <FlexView
        flexDirection="column"
        width={['90vw', '90vw', '45rem']}
        alignItems="center"
        mx="L"
      >
        <Field
          setField={setFullName}
          value={fullName}
          type={'text'}
          placeholder={'Nome'}
        />
        <Field
          setField={setMessage}
          value={message}
          type={'text-area'}
          placeholder={'O que está a pensar?'}
        />
        <Link
          to="#"
          onClick={(e) => {
            window.location.href =
              'mailto:geral@angoelectrica.com?subject=' +
              encodeURI('Portal Angoelectrica') +
              '&body=' +
              encodeURI(message);
            e.preventDefault();
          }}
        >
          <ButtonStyled
            mt="L"
            bg="primary"
            width="10rem"
            height="3rem"
            border="none"
            borderRadius="0.31rem"
            fontSize="1rem"
            fontWeight="600"
            fontFamily="'Montserrat', sans-serif"
            color="textInverted"
            name="contact-us"
            type="button"
          >
            Contacte-nos
          </ButtonStyled>
        </Link>
      </FlexView>
    </form>
  );
};
export default Form;
