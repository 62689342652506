import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { FC } from 'react';
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
} from 'styled-system';

import { FlexViewProps } from './flex-view.types';

const FlexView: FC<FlexViewProps> = ({ as, hover, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const StyledFlexView = styled((as as any) || 'div')(
    {
      display: 'flex',
    },
    css({
      ...(hover && { transition: 'all 250ms ease-in-out', ':hover': hover }),
    }),
    compose(
      color,
      grid,
      space,
      border,
      layout,
      shadow,
      position,
      typography,
      background,
      flexbox
    ),
    system({
      cursor: true,
    })
  );
  // eslint-disable-next-line react/react-in-jsx-scope
  return <StyledFlexView {...props} />;
};

export default FlexView;
