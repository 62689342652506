import React, { FC } from 'react';

import { FlexView, Image } from '../../elements';
import { ItemSlideProps } from './partners.types';

const ItemSlidePartner: FC<ItemSlideProps> = ({ picture, width, height }) => (
  <FlexView
    width={['90%', '90%', width]}
    height={height}
    mx={['5%', '5%', 'XL']}
  >
    <Image
      src={picture}
      alt="SlideShow imagens"
      width="100%"
      height="100%"
      objectFit="contain"
    />
  </FlexView>
);

export default ItemSlidePartner;
