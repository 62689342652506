import { ItemsProps } from './know-more.types';

export const SERVICES: ReadonlyArray<ItemsProps> = [
  {
    description: 'Iluminação',
    image: process.env.PUBLIC_URL + '/iluminacao-min.webp',
  },
  {
    description: 'Ferramentas',
    image: process.env.PUBLIC_URL + '/tools.webp',
  },
  {
    description: 'Para-Raios',
    image: process.env.PUBLIC_URL + '/para-raios.webp',
  },
  {
    description: 'Hidraúlica',
    image: process.env.PUBLIC_URL + '/hidraulica.webp',
  },
  {
    description: 'Ventilação',
    image: process.env.PUBLIC_URL + '/ventilacao.webp',
  },
  {
    description: 'Electricidade',
    image: process.env.PUBLIC_URL + '/eletricidade.webp',
  },
];
