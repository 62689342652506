import { CommentsProps, FeatureProps } from './testimonial.types';

export const COMMENTS_DEFAULT: ReadonlyArray<CommentsProps> = [
  {
    title: 'Fusíveis',
    content:
      'Os fusíveis são equipamentos de segurança que impedem a passagem de corrente quando há uma sobrecarga em um circuito elétrico. Assim, quando há uma passagem de corrente maior do que o fusível suporta, ele queima, impedindo que essa sobrecarga chegue ao circuito.',
  },
  {
    title: 'O que são ferramentas para que servem?',
    content:
      'O termo ferramenta deriva do latim ferramenta, plural de ferramentum. É um utensílio, dispositivo, ou mecanismo físico ou intelectual utilizado por trabalhadores das mais diversas áreas para realizar alguma tarefa.',
  },
];

export const FEATURE_DEFAULT: ReadonlyArray<FeatureProps> = [
  { total: 10, description: 'Marcas certificadas' },
  { total: 2000, description: 'Clientes' },
  { total: 10, description: 'Anos de Mercado' },
];
