import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import { FeatureProps } from './testimonial.types';

const Feature: FC<FeatureProps> = ({ total, description }) => (
  <FlexView flexDirection="column" mb={['2.75rem', '2.75rem', 'auto']}>
    <Text
      variant="title3"
      fontWeight="600"
      textAlign={['center', 'center', 'unset']}
    >
      {total}+
    </Text>
    <Text
      variant="strong"
      fontWeight="300"
      textAlign={['center', 'center', 'unset']}
    >
      {description}
    </Text>
  </FlexView>
);

export default Feature;
