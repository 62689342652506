import React, { FC } from 'react';
import { v4 } from 'uuid';

import Container from '../shared/container';
import Slider from '../slider';
import Comments from './comments';
import { SlideCommentProps } from './testimonial.types';

const SlideComments: FC<SlideCommentProps> = ({ comments }) => (
  <Container
    width={['100%', '100%', '56%']}
    ml={['unset', 'unset', 'unset']}
    pt={['2.1rem', '2.1rem', '2rem']}
    pb={['2rem', '2rem', '3.5rem']}
    bg="textInverted"
    zIndex={2}
  >
    <Slider type="comments">
      {comments.map((comment) => (
        <Comments content={comment.content} title={comment.title} key={v4()} />
      ))}
    </Slider>
  </Container>
);

export default SlideComments;
