import React, { FC, SVGAttributes } from 'react';

const ArrowUpSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 10" {...props}>
    <path
      d="M17 9L9 0.999999L1 9"
      stroke={props?.fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUpSVG;
