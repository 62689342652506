import React, { FC } from 'react';

import {
  About,
  KnowMore,
  PageWrapper,
  Partners,
  SlideShow,
  Testimonial,
} from '../components';

const Home: FC = () => (
  <PageWrapper pageTitle="Início">
    <SlideShow />
    <About />
    <KnowMore />
    <Testimonial />
    <Partners />
  </PageWrapper>
);

export default Home;
