import styled from '@emotion/styled';
import React, { FC } from 'react';
import {
  background,
  border,
  color,
  compose,
  layout,
  space,
  system,
  typography,
  variant,
} from 'styled-system';

import { TextProps } from './text.types';

const Text: FC<TextProps> = ({ as, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const StyledText = styled((as as any) || 'p')(
    variant({
      scale: 'textVariants',
    }),
    compose(color, space, border, layout, typography, background),
    system({
      cursor: true,
    })
  );
  return <StyledText {...props} />;
};

export default Text;
