import React, { FC } from 'react';
import { v4 } from 'uuid';

import { FlexView, Text } from '../../elements';
import FeatureCheck from './features-check';
const ContentGroup: FC = () => (
  <FlexView width={['100%', '100%', '55%']} height={['auto', 'auto', '100%']}>
    <FlexView
      bg="textInverted"
      width="100%"
      my="auto"
      zIndex={2}
      flexDirection="column"
      pl={['unset', 'unset', '3.438rem']}
      py={['1rem', '1rem', '2.375rem']}
    >
      <Text variant="strong" color="primary" fontWeight="600">
        Sobre
      </Text>
      <Text
        variant="title3"
        lineHeight={['2.25rem', '2.25rem', '3.37rem']}
        fontSize={['1.5rem', '1.5rem', '2.069rem']}
      >
        Nossa História
      </Text>
      <Text
        variant="small"
        color="textContrast"
        lineHeight="1.188rem"
        textAlign="justify"
        my="1rem"
      >
        A Angoeléctrica iniciou a actividade em Abril de 2008 com a
        comercialização de material eléctrico em Luanda. Abriu a primeira loja
        na Rua da Ambaca, no nº 203 em São Paulo, onde ainda se encontra
        actualmente. Em Fevereiro de 2010 a empresa sente a necessidade de um
        novo espaço para implementar o seu armazém central. Está localizado na
        antiga estrada da Somef, no Km 26, Viana – Catete. Dentro da estratégia
        de crescimento, em Fevereiro de 2011, é aberta uma sucursal, na Rua Hoji
        Ya Henda, no nº 48 em Viana. Em Junho de 2014, é aberta uma nova
        sucursal , na Via Expresso, no Km 1,2, Via Expresso (Benfica) – Cacuaco.
      </Text>
      {[
        'Lorem ipsum dolor sit, amet consectetur',
        'Lorem ipsum dolor sit, ',
        'Lorem ipsum dolor sit, amet consectetur Lorem ipsum ',
        'Lorem ipsum dolor sit, amet consectetur Lorem ipsum dolor sit',
      ].map((content) => (
        <FeatureCheck description={content} key={v4()} />
      ))}
    </FlexView>
  </FlexView>
);

export default ContentGroup;
