import React, { FC } from 'react';

import { Text } from '../../elements';
const Content: FC = () => (
  <Text
    variant="normal"
    mt={['1.5rem', '1.5rem', '3rem']}
    mb="1.8rem"
    color="textContrast"
    fontWeight="400"
    lineHeight="1.1rem"
  >
    Não hesite em contactarmos, estamos sempre dispostos a prestar-lhe o melhor
    serviço e iluminar o seu futuro
  </Text>
);

export default Content;
