import React, { FC, SVGAttributes } from 'react';

const PhoneSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
    <path
      d="M10.664 11.3332C4.97669 11.3412 0.660022 6.97317 0.666022 1.33517C0.666022 0.966504 0.964689 0.666504 1.33269 0.666504H3.09269C3.42269 0.666504 3.70336 0.909171 3.75202 1.23584C3.86809 2.01921 4.09597 2.78187 4.42869 3.5005L4.49736 3.6485C4.54348 3.7481 4.5534 3.86067 4.52541 3.96679C4.49742 4.07292 4.43327 4.16595 4.34402 4.22984C3.79869 4.61917 3.59069 5.4025 4.01536 6.01317C4.55001 6.78206 5.21732 7.44959 5.98602 7.9845C6.59736 8.4085 7.38069 8.2005 7.76936 7.65584C7.83318 7.56632 7.92628 7.50193 8.03257 7.47381C8.13885 7.44569 8.25161 7.4556 8.35136 7.50184L8.49869 7.56984C9.21736 7.90317 9.98002 8.13117 10.7634 8.24717C11.09 8.29584 11.3327 8.5765 11.3327 8.90717V10.6665C11.3327 10.7541 11.3154 10.8409 11.2819 10.9218C11.2483 11.0027 11.1992 11.0762 11.1372 11.1381C11.0752 11.2001 11.0016 11.2492 10.9207 11.2826C10.8397 11.3161 10.753 11.3333 10.6654 11.3332H10.664Z"
      fill={props.fill}
    />
  </svg>
);

export default PhoneSVG;
