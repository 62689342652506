import React, { FC, SVGAttributes } from 'react';

const ArrowDownSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 10" {...props}>
    <path
      d="M1 1L9 9L17 1"
      stroke={props?.fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowDownSVG;
