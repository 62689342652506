import React, { FC } from 'react';

import { PageWrapper, PartnersByWork } from '../components';
import { RoutesEnum } from '../constants/routes';

const Contact: FC = () => (
  <PageWrapper
    pageTitle="Marcas"
    description="Marcas disponíveis"
    link={RoutesEnum.Partners}
  >
    <PartnersByWork />
  </PageWrapper>
);

export default Contact;
