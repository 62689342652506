import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import { ItemsProps } from './top-bar.types';

const ItemTopBar: FC<ItemsProps> = ({
  description,
  icon,
  space,
  color,
  badge,
}) => (
  <FlexView display="flex" mr={space} position="relative">
    {icon}
    {!badge ? (
      <Text
        variant="small"
        color={color}
        ml={['L', 'L', 'M']}
        fontSize={['1.1rem', '1.1rem', '1rem']}
        cursor="pointer"
      >
        {description}
      </Text>
    ) : (
      <FlexView position="absolute" right="-0.52rem" top="-0.3rem">
        <Text
          variant="small"
          color="textInverted"
          bg="primary"
          ml={['L', 'L', 'M']}
          borderRadius="40rem"
          cursor="pointer"
          px="0.2rem"
          fontSize="0.8rem"
        >
          {description}
        </Text>
      </FlexView>
    )}
  </FlexView>
);

export default ItemTopBar;
