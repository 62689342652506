import { useTheme } from '@emotion/react';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '../../constants/routes';
import { Theme } from '../../design-system/light-theme';
import { FlexView, Text } from '../../elements';
import { CheckSVG } from '../svg';
import { NavItem } from './footer.styles';
import { FeaturesProps } from './footer.types';
const FeatureCheck: FC<FeaturesProps> = ({ description, link }) => {
  const theme = useTheme() as Theme;
  const [color, setColor] = useState(theme.colors.textInverted);
  return (
    <NavItem>
      <Link aria-label="Menu de navegação" to={Routes[link]}>
        <FlexView
          alignItems="center"
          mb="0.25rem"
          onMouseEnter={() => setColor(theme.colors.secondary)}
          onMouseLeave={() => setColor(theme.colors.textInverted)}
        >
          <CheckSVG
            width="1.125rem"
            height="1.125rem"
            fill={theme.colors.secondary}
          />
          <Text
            variant="small"
            ml="L"
            lineHeight="1.5rem"
            letterSpacing="0.05rem"
            color={color}
            fontWeight="600"
            fontSize="1rem"
          >
            {description}
          </Text>
        </FlexView>
      </Link>
    </NavItem>
  );
};

export default FeatureCheck;
