const primitiveColors = {
  PRIMARY: '#B30600',
  SECONDARY: '#FFB0AD',
  NEUTRAL_100: '#000000',
  NEUTRAL_200: '#303442',
  NEUTRAL_300: '#7B7B7B',
  NEUTRAL_400: '#666666',
  NEUTRAL_500: '#BBBDC3',
  NEUTRAL_600: '#282828',
  NEUTRAL_700: '#E0E0E0',
  NEUTRAL_800: '#595959',
  NEUTRAL_900: '#FFFFFF',
  YELLOW_100: '#FBEB97',
  GREEN_100: '#97FBAD',
  RED_100: '#F27A7A',
};

export default primitiveColors;
