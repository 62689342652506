import React, { FC } from 'react';

import { FlexView, Image } from '../../elements';
import CardEmergencyNumber from './card-emergency-number';

const CardImage: FC = () => (
  <FlexView
    width={['100%', '100%', '50%']}
    height={['100%', '100%', '26rem']}
    position={['relative', 'relative', 'absolute']}
    right="0"
    bottom="0"
    flexDirection="column"
    bg="textInverted"
  >
    <Image
      src={
        process.env.PUBLIC_URL +
        '/electrician-tightening-an-electrical-panel.webp'
      }
      width="100%"
      height="100%"
      alt="Eletricista apertando um quadro electrico"
    />
    <CardEmergencyNumber />
  </FlexView>
);
export default CardImage;
