import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Routes, RoutesEnum } from '../../constants/routes';
import { FlexView, Text } from '../../elements';
import { ItemProps } from '../partners/partners.types';
import { LeftArrowSVG } from '../svg';

export const Item: FC<ItemProps> = ({ title }) => (
  <FlexView
    display="flex"
    alignItems="center"
    cursor="pointer"
    py={['0.75rem', '0.75rem', '0.30rem']}
  >
    <Link
      aria-label="categorias"
      to={`${Routes[RoutesEnum.Partners]}${title != 'Todas' ? title : ''}`}
    >
      <LeftArrowSVG width="0.8rem" height="0.8rem" />
      <Text
        as="span"
        fontFamily="Roboto"
        lineHeight={'0.8rem'}
        fontWeight="400"
        fontSize={['1.5rem', '1.5rem', '1rem']}
        ml="M"
        color={['text', 'text', 'border']}
      >
        {title}
      </Text>
    </Link>
  </FlexView>
);
