import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import { FeaturesProps } from './who-we-are.types';
const FeatureCheck: FC<FeaturesProps> = ({ description, total, icon }) => (
  <FlexView
    alignItems="center"
    mb="0.25rem"
    width={['100%', '100%', '49%']}
    mt={['1rem', '1rem', 'unset']}
  >
    <FlexView
      width="4rem"
      height="4rem"
      bg="primary"
      borderRadius="4rem"
      alignItems="center"
      justifyContent="center"
      mr="M"
    >
      {icon}
    </FlexView>
    <FlexView flexDirection="column" width="60%">
      <Text
        variant="title3"
        fontWeight="600"
        fontSize="2.225rem"
        color="borderOpacity"
      >
        {total}
      </Text>
      <Text variant="small" pr={['XXL', 'XXL', 'unset']}>
        {description}
      </Text>
    </FlexView>
  </FlexView>
);

export default FeatureCheck;
