import React, { FC } from 'react';

import FlexView from '../flex-view';
import { ImgProps } from './image.types';

const Image: FC<ImgProps> = ({
  height,
  width,
  src,
  alt,
  objectFit = 'cover',
}) => (
  <FlexView width={width} height={height} overflow="hidden">
    <img
      src={src}
      alt={alt}
      width="100%"
      height="100%"
      loading="lazy"
      style={{
        width: '100%',
        height: 'auto',
        objectFit: objectFit,
      }}
    />
  </FlexView>
);

export default Image;
