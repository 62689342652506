import styled from '@emotion/styled';

import { Button, FlexView } from '../../elements';

export const ButtonHover = styled(Button)`
  transition: all 300ms ease-in-out;
  &: hover {
    color: #ff2920;
    background: #fff;
    cursor: pointer;
  }
`;

export const FlexViewScale = styled(FlexView)`
  transition: all 300ms ease-in-out;
  transform: scale(1);
  &: hover {
    transform: scale(1.05);
    z-index: 2;
  }
`;
