import React, { FC, SVGAttributes } from 'react';

const ClockSVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
    <path
      d="M7.00065 0.333496C3.33398 0.333496 0.333984 3.3335 0.333984 7.00016C0.333984 10.6668 3.33398 13.6668 7.00065 13.6668C10.6673 13.6668 13.6673 10.6668 13.6673 7.00016C13.6673 3.3335 10.6673 0.333496 7.00065 0.333496ZM7.00065 12.3335C4.06065 12.3335 1.66732 9.94016 1.66732 7.00016C1.66732 4.06016 4.06065 1.66683 7.00065 1.66683C9.94065 1.66683 12.334 4.06016 12.334 7.00016C12.334 9.94016 9.94065 12.3335 7.00065 12.3335ZM7.33398 3.66683H6.33398V7.66683L9.80065 9.80016L10.334 8.9335L7.33398 7.1335V3.66683Z"
      fill={props.fill}
    />
  </svg>
);

export default ClockSVG;
