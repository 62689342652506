import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

import { Routes, RoutesEnum } from '../../constants/routes';
import { Nav, Ul } from '../../elements';
import { Li, NavItemWrapper } from './nav-bar.styles';

const NavList: FC = () => {
  const links = [
    { description: 'Início', link: RoutesEnum.Home },
    { description: 'Sobre', link: RoutesEnum.About },
    { description: 'Marcas', link: RoutesEnum.Partners },
    { description: 'Contactos', link: RoutesEnum.Contacts },
  ];
  return (
    <Nav width={['100%', '100%', '30%']}>
      <Ul
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        width="100%"
        py={['M', 'M', 'unset']}
        alignItems={['flex-end', 'flex-end', 'unset']}
      >
        {links.map((item) => (
          <Li key={v4()}>
            <Link aria-label={item.description} to={Routes[item.link]}>
              <NavItemWrapper
                variant="large"
                cursor="pointer"
                fontSize="1rem"
                color="text"
                py={['S', 'S', 'unset']}
                fontWeight={['500', '500', '600']}
              >
                {item.description}
              </NavItemWrapper>
            </Link>
          </Li>
        ))}
      </Ul>
    </Nav>
  );
};
export default NavList;
