import React, { FC } from 'react';

import { FlexView } from '../../elements';
import { CallSVG, HomeGroupSVG, SafetySVG } from '../svg';
import Card from './card';
const Advantages: FC = () => (
  <FlexView mt="3.188rem" flexDirection={['column', 'column', 'row']}>
    <Card
      cod={1}
      title={'Produtos Certificados'}
      body={
        'Oferecemos produtos de qualidade certificada, garantindo assim a satisfação dos nossos clientes.'
      }
      icon={<SafetySVG width="2rem" height="2rem" />}
    />
    <Card
      cod={2}
      title={'Suporte'}
      body={
        'Temos uma equipa a disposição para prestar-lhe o melhor atendimento dentro do nosso horário de funcionamento'
      }
      icon={<CallSVG width="2rem" height="2rem" />}
    />
    <Card
      cod={3}
      title={'Diversas Lojas'}
      body={
        'Pode encontrar a Angoeléctica em diversos pontos da cidade de Luanda, como Viana, São Paulo e Via Expresso (Benfica)...'
      }
      icon={<HomeGroupSVG width="2rem" height="2rem" />}
    />
  </FlexView>
);

export default Advantages;
