import React, { FC, SVGAttributes } from 'react';

const SafetySVG: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 35" {...props}>
    <path
      d="M26.3235 5.13896L14.0456 0.954785C13.8975 0.904199 13.6987 0.878906 13.5 0.878906C13.3013 0.878906 13.1025 0.904199 12.9544 0.954785L0.676465 5.13896C0.376563 5.24014 0.130859 5.58701 0.130859 5.90498V23.3354C0.130859 23.6534 0.336817 24.0726 0.586133 24.2713L13.0411 33.9766C13.1676 34.0741 13.3302 34.1247 13.4964 34.1247C13.6626 34.1247 13.8288 34.0741 13.9517 33.9766L26.4066 24.2713C26.656 24.0762 26.8619 23.657 26.8619 23.3354V5.90498C26.8691 5.58701 26.6234 5.24375 26.3235 5.13896ZM24.2676 22.6417L13.5 31.0317L2.73242 22.6417V7.19131L13.5 3.52021L24.2676 7.19131V22.6417ZM9.60488 15.379C9.49649 15.2309 9.32305 15.1405 9.13516 15.1405H7.14063C6.90576 15.1405 6.76846 15.4079 6.90576 15.5994L11.4729 21.8865C11.527 21.9604 11.5976 22.0206 11.6793 22.062C11.7609 22.1035 11.8511 22.1251 11.9427 22.1251C12.0342 22.1251 12.1245 22.1035 12.2061 22.062C12.2877 22.0206 12.3584 21.9604 12.4124 21.8865L20.0942 11.3104C20.2315 11.1189 20.0942 10.8516 19.8594 10.8516H17.8648C17.6806 10.8516 17.5035 10.9419 17.3951 11.09L11.9427 18.5984L9.60488 15.379Z"
      fill="#B30600"
    />
  </svg>
);

export default SafetySVG;
