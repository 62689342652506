import styled from '@emotion/styled';

import { Text } from '../../elements';

export const NavItemWrapper = styled(Text)`
  transition: all 300ms ease-in-out;
  list-style: none;
  &: hover {
    color: #ff2920;
  }
`;

export const Li = styled.li`
  list-style: none;
`;
