import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { LayoutProps } from './layout.types';

// eslint-disable-next-line react/prop-types
const Layout: FC<LayoutProps> = ({ pageTitle = '', children }) => (
  <>
    <Helmet>
      <html lang="pt-PT" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Roboto:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <meta name="title" content="AngoEléctrica" />
      <meta
        name="description"
        content="EMPRESA DE DISTRIBUIÇÃO DE MATERIAL ELECTRICO 19h55 Contacto: 922 592 266 Localização: Rua da Ambaca nº 203 S. Paulo (junto a Igreja Católica de S.Paulo)."
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.angoelectrica.com/" />
      <meta property="og:title" content="AngoEléctrica" />
      <meta
        property="og:description"
        content="EMPRESA DE DISTRIBUIÇÃO DE MATERIAL ELECTRICO 19h55 Contacto: 922 592 266 Localização: Rua da Ambaca nº 203 S. Paulo (junto a Igreja Católica de S.Paulo)."
      />
      <meta
        property="og:image"
        content={process.env.PUBLIC_URL + '/android-icon-96x96.png'}
      />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://www.angoelectrica.com/" />
      <meta property="twitter:title" content="AngoEléctrica" />
      <meta
        property="twitter:description"
        content="EMPRESA DE DISTRIBUIÇÃO DE MATERIAL ELECTRICO 19h55 Contacto: 922 592 266 Localização: Rua da Ambaca nº 203 S. Paulo (junto a Igreja Católica de S.Paulo)."
      />
      <meta
        property="twitter:image"
        content={process.env.PUBLIC_URL + '/android-icon-96x96.png'}
      />
      <title>AngoElectrica | {pageTitle}</title>
    </Helmet>
    {children}
  </>
);

export default Layout;
