import styled from '@emotion/styled';

import { Button } from '../../elements';

export const ButtonHover = styled(Button)`
  transition: all 300ms ease-in-out;
  &: hover {
    background: #000;
    cursor: pointer;
  }
`;
