import React, { FC } from 'react';

import { Layout, SlideShow, TopBar } from '../components';
import { View } from '../elements';

const OtherPage: FC = () => (
  <Layout pageTitle={'Other Page'}>
    <TopBar />
    <SlideShow />
    <View>
      <h1>Other Page</h1>
    </View>
  </Layout>
);

export default OtherPage;
