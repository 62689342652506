import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import { CommentsProps } from './testimonial.types';
import TitleContent from './title-content';

const Content: FC<CommentsProps> = ({ content, title }) => (
  <FlexView flexDirection="column">
    <TitleContent title={title} />
    <Text
      variant="normal"
      color="textContrast"
      lineHeight="1.18rem"
      my="1rem"
      mr={['1.8rem', '1.8rem', '2rem']}
    >
      {content}
    </Text>
  </FlexView>
);

export default Content;
