import React, { FC } from 'react';

import { Text } from '../../elements';
import ContentGroupWrapper from './content-group-wrapper';
import Form from './form';
const ContentGroup: FC = () => (
  <ContentGroupWrapper>
    <Text
      variant="strong"
      color="primary"
      fontWeight="600"
      textAlign="center"
      lineHeight="1.18rem"
    >
      Diga-nos o que procura
    </Text>
    <Text
      variant="title3"
      lineHeight={['2.25rem', '2.25rem', '3.37rem']}
      fontSize={['1.5rem', '1.5rem', '2.575rem']}
      textAlign="center"
    >
      Nós Contactamos
    </Text>
    <Text
      display="none"
      variant="small"
      color="textContrast"
      textAlign="center"
      my="0.7rem"
    >
      Você está perplexo com um projeto ou problema de fiação doméstica?
      Preencha o formulário com os detalhes da sua situação e podemos ajudá-lo.
    </Text>
    <Form />
  </ContentGroupWrapper>
);

export default ContentGroup;
