import React, { FC } from 'react';

import { FlexView } from '../../elements';
import { MapsProps } from './maps.types';

const Map: FC<MapsProps> = ({ currentMap }) => (
  <FlexView
    width={['100%', '100%', '80%']}
    height="25rem"
    mb="2.6rem"
    mt={['1.5rem', '1.5rem', 'unset']}
  >
    <iframe
      title="Nossa localização - AngoElectrica"
      src={currentMap}
      width="100%"
      height="100%"
      frameBorder="0"
      allowFullScreen
      loading="lazy"
    ></iframe>
  </FlexView>
);

export default Map;
