import { useTheme } from '@emotion/react';
import React, { FC, useState } from 'react';
import { v4 } from 'uuid';

import { Theme } from '../../design-system/light-theme';
import { Button, FlexView } from '../../elements';
import { Container } from '../shared';
import { ArrowDownSVG, ArrowUpSVG, ClockSVG } from '../svg';
import ItemAddress from './item-address';
import ItemTopBar from './item-top-bar';
import { ADDRESS } from './top-bar.data';

const MobileTopBar: FC = () => {
  const theme = useTheme() as Theme;
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <>
      <Container
        display={['flex', 'flex', 'none']}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Button
          border="none"
          bg="transparent"
          height="3rem"
          p="M"
          name="topbar-button"
          aria-label="topbar"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          {toggleMenu ? (
            <ArrowUpSVG width="1rem" fill={theme.colors.textInverted} />
          ) : (
            <ArrowDownSVG width="1rem" fill={theme.colors.textInverted} />
          )}
        </Button>
      </Container>
      {toggleMenu && (
        <Container
          px={['M', 'M', 'unset']}
          py="L"
          flexDirection={['column', 'column', 'row']}
        >
          <ItemTopBar
            description="Seg - Sex: 8h00 - 12h30 e 13h30 - 17h00 | Sáb: 8h00 - 12h00"
            color={theme.colors.semiSaturated}
            icon={
              <ClockSVG
                width="1rem"
                height="1rem"
                fill={theme.colors.primary}
              />
            }
          />

          <FlexView my="L" flexDirection="column">
            {ADDRESS.map((item) => (
              <ItemAddress
                address={item.address}
                phone={item.phone}
                key={v4()}
              />
            ))}
          </FlexView>
        </Container>
      )}
    </>
  );
};

export default MobileTopBar;
