import React, { FC } from 'react';

import { FlexView } from '../../elements';
import { ItemSlideWrapperProps } from './slideshow.types';

const ItemSlideWrapper: FC<ItemSlideWrapperProps> = ({ picture, children }) => (
  <FlexView
    background={`url('${picture}') no-repeat center center`}
    backgroundSize="cover"
    width="100vw"
    height="100vh"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    px="10%"
    position="relative"
  >
    <FlexView
      width="100%"
      height="100%"
      position="absolute"
      bg="rgba(3, 3, 3, 0.4);"
    />
    {children}
  </FlexView>
);

export default ItemSlideWrapper;
