import { useTheme } from '@emotion/react';
import React, { FC } from 'react';
import { v4 } from 'uuid';

import { Theme } from '../../design-system/light-theme';
import { FlexView, Text } from '../../elements';
import { AwardSVG, HomeGroupSVG } from '../svg';
import FeatureCheck from './features-check';

const ContentGroup: FC = () => {
  const theme = useTheme() as Theme;
  return (
    <FlexView
      mt={['20%', '20%', 'unset']}
      width={['100%', '100%', '60%']}
      px={['unset', 'unset', '3.438rem']}
      py={['1rem', '1rem', '4rem']}
      height={['auto', 'auto', '100%']}
    >
      <FlexView width="100%" flexDirection="column">
        <Text variant="strong" color="primary" fontWeight="600">
          Mais de 10 anos de experiência
        </Text>
        <Text
          fontFamily="'Montserrat', sans-serif"
          lineHeight={['2.25rem', '2.25rem', '3rem']}
          fontWeight="600"
          fontSize={['1.5rem', '1.5rem', '2.25rem']}
          mt={['M', 'M', '1.5rem']}
        >
          Equipamentos eléctricos confiáveis e de qualidade
        </Text>
        <Text variant="normal" color="textContrast" lineHeight="1.188rem">
          Começamos em 2008, a Angoeléctrica é representada por um conjunto de
          valores, rigor e eficiência em tudo o que fazemos, trabalhamos com
          seriedade e transparência no nosso relacionamento com o cliente.
        </Text>
        <FlexView
          mt="3.188rem"
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
        >
          <FeatureCheck
            total="+15"
            icon={<AwardSVG width="2rem" height="2rem" />}
            description="Familias de productos qualificados"
            key={v4()}
          />

          <FeatureCheck
            total={3}
            icon={
              <HomeGroupSVG
                fill={theme.colors.textInverted}
                width="2rem"
                height="2rem"
              />
            }
            description="Lojas espalhadas por Luanda"
            key={v4()}
          />
        </FlexView>
      </FlexView>
    </FlexView>
  );
};
export default ContentGroup;
