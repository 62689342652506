import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import { Container } from '../shared';
import { CogsSVG } from '../svg';
import ContentGroup from './content-group';
import SideBar from './side-bar';
const PartnersByWork: FC = () => (
  <Container
    my="unset !important"
    justifyContent="center"
    flexDirection={['column', 'column', 'row']}
    mt={['-4rem !important', '-4rem !important', 'unset !important']}
    pb="XL"
  >
    <FlexView
      width="100%"
      minHeight="4.188rem"
      display={['flex', 'flex', 'none']}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      borderTop="0.13rem solid #36324A"
      hover={{ ' & > .submenu-bar': { display: 'block' } }}
    >
      <FlexView display="flex" py="M">
        <CogsSVG width="1.2rem" />
        <Text textAlign="center" ml="M" fontSize="1.4rem">
          Categoria
        </Text>
      </FlexView>
      <SideBar />
    </FlexView>
    <SideBar />
    <ContentGroup />
  </Container>
);

export default PartnersByWork;
