import React, { FC } from 'react';

import { Text } from '../../elements';
import Advantages from './advantages';
import ContentGroupWrapper from './content-group-wrapper';
const ContentGroup: FC = () => (
  <ContentGroupWrapper>
    <Text
      variant="strong"
      color="primary"
      fontWeight="600"
      textAlign="center"
      lineHeight="1.18rem"
    >
      Nossas vantagens
    </Text>
    <Text
      variant="title3"
      lineHeight={['2.25rem', '2.25rem', '3.37rem']}
      fontSize={['1.5rem', '1.5rem', '2.575rem']}
      textAlign="center"
    >
      Motivos pelos quais você deve nos ligar
    </Text>
    <Text variant="small" color="textContrast" textAlign="center" my="0.7rem">
      A Angoeléctrica existe para proporcionar aos seus clientes, uma oferta
      alargada de produtos eléctricos.
    </Text>
    <Advantages />
  </ContentGroupWrapper>
);

export default ContentGroup;
