import React, { FC } from 'react';

import { FlexView, Text } from '../../elements';
import { WhatsappSVG } from '../svg';
import { FeaturesStoreProps } from './footer.types';

const FeatureStore: FC<FeaturesStoreProps> = ({ title, location, phone }) => (
  <FlexView
    flexDirection="column"
    width={['100%', '100%', 'auto']}
    mt={['1rem', '1rem', 'unset']}
    mb={['1rem', '1rem', '1rem']}
  >
    <Text variant="normal" color="textInverted" fontWeight="600" mb="S">
      {title}
    </Text>
    <Text variant="small" color="semiSaturated" mb="XS">
      <Text as="span" fontWeight="bold">
        Localização: {' ' + location}
      </Text>
    </Text>
    <a
      href={`https://wa.me/${phone.replaceAll(' ', '')}`}
      target="_blank"
      rel="noreferrer"
    >
      <Text variant="small" color="semiSaturated" mt="S">
        <Text as="span" fontWeight="bold">
          <WhatsappSVG width="1.05rem" height="1.05rem" />

          {' (+244) ' + phone}
        </Text>
      </Text>
    </a>
  </FlexView>
);

export default FeatureStore;
