export const DESCRIPTION_ANGOELECTRICA =
  'Começamos em 2008, a Angoeléctrica é representada por um conjunto de valores, rigor e eficiência em tudo o que fazemos, trabalhamos com seriedade e transparência no nosso relacionamento com o cliente.';

export const FEATURES_STORES = [
  {
    title: 'Loja 1',
    location: 'São Paulo',
    phone: '928 689 936',
  },
  {
    title: 'Loja 2',
    location: 'Viana',
    phone: '935 174 070',
  },
  {
    title: 'Loja 3',
    location: 'Via expresso (Benfica)',
    phone: '932 223 159',
  },
];
