import { Settings } from 'react-slick';

export const getSettings = (
  type: 'hero' | 'know-more' | 'comments' | 'partners' | 'know-more-full'
): Settings =>
  type === 'hero'
    ? {
        dots: false,
        infinite: true,
        speed: 2500,
        autoplaySpeed: 6500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
      }
    : ['know-more', 'comments'].includes(type)
    ? {
        dots: type === 'comments' ? true : true,
        infinite: true,
        speed: 1500,
        autoplaySpeed: 5500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
      }
    : type === 'partners'
    ? {
        dots: false,
        infinite: true,
        speed: 1500,
        autoplaySpeed: 3500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 832,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      }
    : type === 'know-more-full'
    ? {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1500,
        autoplaySpeed: 3500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        initialSlide: 0,
      }
    : {};
