import React, { FC } from 'react';

import { FlexView } from '../../elements';
import FullTopBar from './full-top-bar';
import MobileTopBar from './mobile-top-bar';

const TopBar: FC = () => (
  <FlexView
    width="100vw"
    bg="saturated"
    minHeight="2.188rem"
    flexDirection={['column', 'column', 'row']}
    px="M"
  >
    <FullTopBar />
    <MobileTopBar />
  </FlexView>
);

export default TopBar;
